import { SiteCode } from "@/models/Site";
import { SparePart } from "../SparePart";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";
import { AttachmentViewModel } from "@/models/viewmodels/AttachmentViewModel";
import { clone, functionallyEqual } from "@/utils";

/**
 * The editable fields of a work order activity which can be uploaded for updates.
 */
export class ActivityChanges {
  woid?: number;
  activityid: number;
  site_code: SiteCode;
  completed: 0 | 1 | null = null;
  notes: string | null = null;
  input_name: string | null = null;
  spare_parts: SparePart[] = [];
  attachments: AttachmentViewModel[] | undefined = undefined;

  constructor(
    id: number,
    woid: number,
    siteCode: SiteCode,
    spare_parts: SparePart[]
  ) {
    this.activityid = id;
    this.woid = woid;
    this.site_code = siteCode;
    this.spare_parts = spare_parts;
  }

  /**
   * Gathers any changes made to a work order's activities in the UI.
   *
   * @param updated The work order copy that has been opened in the UI.
   * @returns Any differences from the original's activities.
   */
  static from(updated: WorkOrderViewModel): ActivityChanges[] {
    const changesList: ActivityChanges[] = [];

    updated.activities.forEach((activity) => {
      let sparePartChanges =
        activity.snapshot.spare_parts.length !== activity.spare_parts.length;
      if (!sparePartChanges) {
        for (const sp of activity.snapshot.spare_parts) {
          let found = false;
          for (const spn of activity.spare_parts) {
            if (sp.inventory_id === spn.inventory_id) {
              found = true;
              if (sp.quantity !== spn.quantity) sparePartChanges = true;
              break;
            }
          }
          if (!found || sparePartChanges) break;
        }
      }

      if (
        sparePartChanges ||
        !functionallyEqual(activity.snapshot.notes, activity.notes) ||
        !functionallyEqual(activity.snapshot.input_name, activity.input_name) ||
        !!activity.snapshot.completed !== !!activity.completed ||
        this._anyAttachmentsDeleted(activity) ||
        this._anyAttachmentsAdded(activity)
      ) {
        const change: ActivityChanges = new ActivityChanges(
          activity.activityid,
          updated.woid,
          updated.site_code,
          activity.spare_parts
        );
        change.completed = activity.completed;
        change.notes = activity.notes;
        change.input_name = activity.input_name;
        change.attachments = clone(activity.attachments);
        changesList.push(change);
      }
    });
    return changesList;
  }

  private static _anyAttachmentsDeleted(activity: ActivityViewModel): boolean {
    if (activity.attachments) {
      for (const attachment of activity.attachments) {
        if (attachment.deleted) return true;
      }
    }
    return false;
  }

  private static _anyAttachmentsAdded(activity: ActivityViewModel): boolean {
    if (activity.attachments) {
      for (const attachment of activity.attachments) {
        // docid will be a number when loaded from the server
        if (typeof attachment.docid !== "number") return true;
      }
    }
    return false;
  }
}
