import appConfig from "@/app.config.json";
import { userTable } from "@/db";
import { LanguageCode, setLocale } from "@/locales";
import { LogEntry, Severity } from "@/models/Log";
import { AutoSyncSetting, User } from "@/models/User";
import { WorkOrderCollation } from "@/models/WorkOrder";
import { HTTPResponse } from "@/services";
import { login as authorize } from "@/services/auth-services";
import { applyFilter } from "@/utils/persistence";
import { clone } from "@/utils";
import { SearchbarCustomEvent } from "@ionic/vue";
import { AxiosRequestHeaders } from "axios";
import { defineStore } from "pinia";

import { log } from "./LogStore";
import { getSiteStore } from "@/stores/SiteStore";
import { getSparePartStore } from "@/stores/SparePartStore";
import { getSyncStore } from "@/stores/SyncStore";
import { getWorkOrderStore } from "@/stores/WorkOrderStore";
import { getWorkOrderTypeStore } from "./WorkOrderTypeStore";

export const getUserStore = defineStore("User", {
  state: (): User => User.init(),
  getters: {
    /**
     * An accessor to create headers for CSEye REST API calls.
     *
     * @returns The headers, including the bearer JWT token.
     */
    requestHeaders(state: User): AxiosRequestHeaders {
      const headers: AxiosRequestHeaders = {} as AxiosRequestHeaders;
      headers["Authorization"] = `JWT ${state.token}`;
      return headers;
    },

    isEntryVisible() {
      return (entry: LogEntry): boolean => {
        if (entry.severity <= this.severityFilter) {
          return (
            this.logFilterText === "" ||
            entry.key
              .toLowerCase()
              .includes(this.logFilterText.toLowerCase()) ||
            entry.summary
              .toLowerCase()
              .includes(this.logFilterText.toLowerCase())
          );
        }
        return false;
      };
    },

    shouldAutoSync(_state: User) {
      return (): boolean => {
        if (_state.autoSync === AutoSyncSetting.AUTO_WIFI) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (navigator.connection) return navigator.connection.type == "wifi";

          // if we can not detect if we are using wifi, default to manual
          return false;
        } else if (_state.autoSync === AutoSyncSetting.MANUAL) {
          return false;
        } else {
          // if the value is not set, default to auto
          // same as state.autoSync == AutoSyncSetting.AUTO
          return true;
        }
      };
    },
  },
  actions: {
    async authenticated(): Promise<boolean> {
      const user = await userTable.getItem<User>(appConfig.userPersistenceKey);
      return user?.token ? true : false;
    },

    /**
     * Attempts to authenticate with the provided password.
     * Obtains a JWT token if successful, and updates this user store.
     *
     * @param password  - The user's password.
     * @returns This user store.
     */
    async login(
      username: string,
      password: string
    ): Promise<HTTPResponse<User>> {
      const response = await authorize(username, password);
      if (response.status < 400 && response.payload) {
        if (response.payload) {
          const existingUser =
            (await userTable.getItem<User>(
              `${appConfig.userPersistenceKey}:${this.username}`
            )) ?? "";

          this.$state = existingUser ? existingUser : User.init();

          this.username = username;
          this.token = response.payload.token;
          this.$state.token = response.payload.token;
          this.firstName = response.payload.firstName;
          this.lastName = response.payload.lastName;
          this.assignedTo = response.payload.assignedTo;
          await this.persist();

          await getSyncStore().closeWorkOrder();
          await getSyncStore().updateLastPeriodicCheck();
          getSiteStore().update();
          getWorkOrderTypeStore().update();
          getSparePartStore().update();
          // await getWorkOrderStore().update(true);
          getSyncStore().synchronize(true);

          // set language from response only on first login
          if (!existingUser) this.language = response.payload.language;
          setLocale(this.language);
        }
      }
      await this.persist();
      return response;
    },

    async replaceToken(newToken: string): Promise<void> {
      // only replace token if currently logged in
      if (this.token) {
        this.token = newToken;
        await this.persist();
      }
    },

    /**
     * Deletes the user auth token to indicate the user is no
     * longer logged in, cleans up the background sync key, and
     * remembers the last logged in user by name.
     */
    async logout(): Promise<void> {
      const syncStore = getSyncStore();
      log().info(`UserStore.logout`, this.username, {
        username: this.username,
        synchronizationIncomplete: syncStore.synchronizationIncomplete,
        currentWorkOrder: clone(syncStore.currentWorkOrder),
        currentActivity: clone(syncStore.currentActivity),
        queued: clone(syncStore.queued),
        pending: clone(syncStore.pending),
        synchronized: clone(syncStore.synchronized),
      });

      this.token = "";
      this.$state.token = "";
      this.last_username = this.username;
      await this.persist();

      // reset all pinia stores, except User
      getSiteStore().purge();
      getSparePartStore().purge();
      // await syncStore.purge();
      await getWorkOrderStore().purge();
      log().purge();
    },

    setLanguage(language: LanguageCode) {
      this.language = language;
      setLocale(language);
      this.persist();
    },

    setMaxAttachmentDownloadSize(size: number) {
      this.maxAttachmentDownloadSize = size;
      this.persist();
    },

    setCollation(collation: WorkOrderCollation) {
      this.collation = collation;
      this.persist();
    },

    setAutoSync(autoSyncLevel: AutoSyncSetting) {
      this.autoSync = autoSyncLevel;
      this.persist();
    },

    setSyncInterval(interval: number) {
      this.syncInterval = interval;
      this.persist();
    },

    setPingInterval(interval: number) {
      this.pingInterval = interval;
      this.persist();
    },

    setLogFilterText(event: SearchbarCustomEvent): void {
      this.logFilterText = event.target?.value ?? "";
      this.persist();
    },

    clearTextFilter(): void {
      this.logFilterText = "";
      this.persist();
    },

    setLoggingLevel(level: Severity = Severity.INFO): void {
      this.loggingLevel = level;
      log().setLoggingLevel(level);
      this.persist();
    },

    setSeverityFilter(level: Severity): void {
      this.severityFilter = level;
      this.persist();
    },

    clearSeverityFilter(): void {
      this.severityFilter = Severity.INFO;
      this.persist();
    },

    /**
     * Stores the current state of the user in the database.
     *
     * For rehydration, call the pinia method getUserStore().$reset()
     */
    async persist(): Promise<void> {
      log().debug(
        `UserStore.persist`,
        this.username,
        applyFilter(this.$state, User.PERSISTENCE_FIELDS)
      );

      await userTable.setItem(
        appConfig.userPersistenceKey,
        clone(applyFilter(this.$state, User.PERSISTENCE_FIELDS))
      );

      // keep a copy of each user's preferences
      if (this.username) {
        await userTable.setItem(
          `${appConfig.userPersistenceKey}:${this.username}`,
          clone(applyFilter(this.$state, appConfig.userPersistenceKey))
        );
      }
    },

    /**
     * Hydrate from IndexedDB
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating UserStore: ${this.hydrated} `);

      if (!this.hydrated) {
        const data = await userTable.getItem<User>(
          appConfig.userPersistenceKey
        );
        if (data) {
          // console.log(JSON.stringify(hydrated));
          this.$state.username = data.username;
          this.$state.firstName = data.firstName;
          this.$state.lastName = data.lastName;
          this.$state.token = data.token;
          this.$state.assignedTo = data.assignedTo;
          this.$state.language = data.language;
          this.$state.collation = data.collation;
          this.$state.pingInterval =
            data.pingInterval ?? appConfig.defaultPingInterval;
          this.$state.syncInterval =
            data.syncInterval ?? appConfig.defaultSyncInterval;
          this.$state.autoSync = data.autoSync ?? appConfig.defaultAutoSync;
          this.$state.maxAttachmentDownloadSize =
            data.maxAttachmentDownloadSize ?? appConfig.defaultMaxDownloadSize;
          this.$state.loggingLevel = data.loggingLevel;
          this.$state.severityFilter = data.severityFilter;
          this.$state.last_username = data.last_username;
          setLocale(this.$state.language);
          log().setLoggingLevel(this.$state.loggingLevel);
        }
        this.$state.hydrated = true;

        log().debug(
          "UserStore.hydrate()",
          `${data ? "" : "NOT "}hydrated from IndexedDB`,
          data
        );
      }
    },

    /**
     * Removes spare part store state persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      log().debug(`UserStore.purge`, ``);
      await userTable.clear();
      this.$state = User.init();
      await this.persist();
    },
  },
});

/**
 * Fetches the persisted user.
 *
 * @returns The state of any User that has been persisted, undefined otherwise
 */
/* private */ export async function loadUser(): Promise<User | null> {
  return await userTable.getItem<User>(appConfig.userPersistenceKey);
}
