import i18n from "@/locales";
import { log } from "@/stores/LogStore";
import { getSyncStore } from "@/stores/SyncStore";
import { WorkerNotification } from "@/models/sync/SyncContext";
import { toastController } from "@ionic/vue";
import { informationCircle } from "ionicons/icons";

export class SyncWorker {
  static _syncWorker: Worker;
  constructor() {
    if (!SyncWorker._syncWorker) {
      SyncWorker._syncWorker = new Worker(
        new URL("@/services/sync-worker", import.meta.url)
      );
      SyncWorker.setupEvents();
    }
  }

  public static setupEvents() {
    SyncWorker._syncWorker.addEventListener(
      "message",
      async (e: MessageEvent<WorkerNotification>) => {
        if (e.data) {
          const notification = e.data;

          if (notification.periodicUpdates)
            // run periodic updates (sites, work order types, ...)
            getSyncStore().periodicUpdates();

          if (notification.sync)
            // patch the syncStore with these updates
            getSyncStore().synchronize();

          if (notification.gc) getSyncStore().gc();

          if (notification.lastPing)
            getSyncStore().updateLastPing(notification.lastPing);

          if (notification.online != null)
            getSyncStore().updateOnline(notification.online);

          if (notification.message) {
            // display the worker's message
            toastController
              .create({
                message: notification.message,
                duration: -1,
                icon: informationCircle,
                position: "top",
                buttons: [
                  {
                    text: i18n.value.LoginView.ok,
                    role: "confirm",
                    handler: () => {
                      log().debug(
                        `notification from sync-worker`,
                        `${notification.message}`
                      );
                    },
                  },
                ],
              })
              .then((toast) => toast.present());
          }

          if (notification.log)
            log().log(
              notification.log.key,
              notification.log.severity,
              notification.log.summary,
              notification.log.detail,
              notification.log.elapsed
            );
        }
      }
    );
  }
  /**
   * Awakens the sync worker to perform background synchronization on the current
   * User's schedule.  May be called repeatedly.
   */
  public static start(): void {
    SyncWorker._syncWorker.postMessage({ op: "start" });
  }

  /**
   * Prevents the sync worker from performing background synchronizatiion.
   */
  public static stop(): void {
    SyncWorker._syncWorker.postMessage({ op: "stop" });
  }

  /**
   * Pauses the sync worker from performing background synchronizatiion for a given time
   */
  public static tempPause(): void {
    SyncWorker._syncWorker.postMessage({ op: "tempPause" });
  }
}
