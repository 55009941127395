import { clone, now } from "@/utils";
import { v4 as uuidv4 } from "uuid";

export enum Severity {
  VERBOSE = 5,
  DEBUG = 4,
  INFO = 3,
  WARN = 2,
  ERROR = 1,
}

export class Log {
  entries: LogEntry[] = [];
  loggingLevel: Severity = Severity.INFO;
  hydrated?: boolean = false;

  /**
   * In the absence of any other log entry data, this is the default state.
   */
  private static readonly DEFAULT: Log = {
    entries: [],
    loggingLevel: Severity.INFO,
    hydrated: false,
  };

  /**
   * Create a new instance of the DEFAULTs
   * @returns A clone of the DEFAULT state.
   */
  static init() {
    return clone(Log.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for WorkOrderFilters.
   */
  static readonly PERSISTENCE_FIELDS: Log = {
    entries: [],
    loggingLevel: Severity.INFO,
  };

  /**
   * Extracts fields typically found in throwables, and will not return cyclical or other references that
   * cannot be logged in IndexedDB.
   *
   * @param err As thrown from a try block.
   * @returns Useful fields for the log.
   */
  static fromException(err: unknown): unknown {
    if (!err) return err;
    const error = err as any;
    const result: any = {};
    if ("name" in error) result.name = error["name"];
    if ("message" in error) result.message = error["message"];
    if ("code" in error) result.code = error["code"];
    if ("status" in error) result.status = error["status"];
    if ("response" in error) {
      const response = error["response"];
      if ("status" in response) result.responseStatus = response["status"];
      if ("statusText" in response)
        result.responseStatusText = response["statusText"];
    }
    return result;
  }
}

/**
 * Provides a structure for log entries with a required key and entry, and an optional note.
 */
export class LogEntry {
  uuid: string;
  key: string;
  timestamp: string;
  summary: string;
  detail?: any;
  elapsed?: number;
  severity: Severity = Severity.INFO;

  constructor(
    key: string,
    severity: Severity,
    note: string,
    details?: any,
    elapsed?: number
  ) {
    this.uuid = uuidv4();
    this.key = key;
    this.severity = severity;
    this.timestamp = now();
    this.summary = note;
    this.detail = details ? details : undefined;
    this.elapsed = elapsed ? elapsed : undefined;
  }
}
