import { Attachment } from "@/models/Attachment";

/**
 * The local copy of a work order activity attachment to be displayed and manipulated by the UI.
 */
export class AttachmentViewModel {
  woid?: number = undefined;
  activityid?: number = undefined;
  docid?: number = undefined;
  file_name?: string = undefined;
  file_size?: number = undefined;
  file_type?: string = undefined;
  content?: string = undefined;
  deleted: boolean = false;

  constructor(attachment?: Attachment) {
    if (attachment) {
      this.woid = attachment.woid;
      this.activityid = attachment.activityid;
      this.docid = attachment.docid;
      this.file_name = attachment.file_name;
      this.file_size = attachment.file_size;
      this.file_type = attachment.file_type;
      this.deleted = false;
    }
  }
}
