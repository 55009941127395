import appConfig from "@/app.config.json";
import localforage from "localforage";

export const attachmentsTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "attachments",
});

export const filesTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "files",
});

export const filtersTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "filters",
});

export const logTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "log",
});

export const sitesTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "sites",
});

export const sparePartsTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "spareParts",
});

export const syncContextTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "syncContext",
});

export const userTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "user",
});

export const workOrdersTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "workOrders",
});

export const workOrderTypesTable = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: `${appConfig.dbName}`,
  storeName: "workOrderTypes",
});

// force all tables to be initialized
attachmentsTable.length();
filesTable.length();
filtersTable.length();
logTable.length();
sparePartsTable.length();
sitesTable.length();
syncContextTable.length();
userTable.length();
workOrdersTable.length();
workOrderTypesTable.length();

export async function reset_database() {
  await attachmentsTable.clear();
  await filesTable.clear();
  await filtersTable.clear();
  await logTable.clear();
  await sparePartsTable.clear();
  await sitesTable.clear();
  await syncContextTable.clear();
  await userTable.clear();
  await workOrdersTable.clear();
  await workOrderTypesTable.clear();
}
