import appConfig from "@/app.config.json";
import { sparePartsTable } from "@/db";
import { Activity } from "@/models/Activity";
import { SparePart, SpareParts } from "@/models/SparePart";
import { WorkOrder } from "@/models/WorkOrder";
import { fetchSpareParts } from "@/services/sparepart-services";
import { clone, fromException } from "@/utils";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { defineStore } from "pinia";
import { Timer } from "timer-node";

import { getSiteStore } from "@/stores/SiteStore";

import { log } from "./LogStore";

export const getSparePartStore = defineStore("SparePart", {
  state: (): SpareParts => SpareParts.init(),
  getters: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sparePartsAvailableFor(_state: SpareParts) {
      return (
        wo: WorkOrder | WorkOrderViewModel,
        activity: Activity | ActivityViewModel,
        filter: string
      ): SparePart[] => {
        const store = getSparePartStore();
        const site = getSiteStore().sites[wo.site_code];
        const relatedSites = site?.related_sites ? site?.related_sites : "";
        const results: SparePart[] = Object.values(store.spareParts).filter(
          (sp) => {
            if (
              (sp.site_code === wo.site_code ||
                relatedSites.indexOf(sp.site_code) !== -1) &&
              sp.onhand_quantity &&
              sp.onhand_quantity > 0 &&
              (!filter ||
                sp.part_description
                  ?.toLowerCase()
                  .includes(filter.toLowerCase()) ||
                sp.part_number?.toLowerCase().includes(filter.toLowerCase()) || sp.site_code?.toLowerCase().includes(filter.toLowerCase()) ||
                sp.part_manufacturer
                  ?.toLowerCase()
                  .includes(filter.toLowerCase()))
            ) {
              let consumed = false;
              if (activity) {
                for (const part of activity.spare_parts) {
                  if (sp.inventory_id === part.inventory_id) {
                    consumed = true;
                    break;
                  }
                }
              }
              return !consumed;
            } else return false;
          }
        );
        return results;
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    consumedSparePartsCount(_state: SpareParts) {
      return (activity: Activity | ActivityViewModel): number => {
        let count = 0;
        activity.spare_parts.forEach((sp) => {
          if (sp.quantity) count += sp.quantity;
        });
        return count;
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // availableSparePartsCount(_state: SpareParts) {
    //   return (wo: WorkOrder | WorkOrderViewModel): number => {
    //     const spareParts = getSparePartStore().spareParts;
    //     const site = getSiteStore().sites[wo.site_code];
    //     const relatedSites = site?.related_sites ? site?.related_sites : "";

    //     let count = 0;
    //     Object.values(spareParts).forEach((sp) => {
    //       if (
    //         sp.site_code === wo.site_code ||
    //         relatedSites.indexOf(sp.site_code) !== -1
    //       ) {
    //         if (sp.onhand_quantity) count += sp.onhand_quantity;
    //       }
    //     });
    //     return count;
    //   };
    // },
  },
  actions: {
    /**
     * Fetches all changed spare parts for the current user's sites and updates the store, unless
     * there is an exception during the call.
     *
     * @returns This spare parts store.
     */
    async update(): Promise<SpareParts> {
      try {
        this.updating = true;

        const newSpareParts = await fetchSpareParts(this.serverTimestamp);
        const previousTimestamp = this.serverTimestamp;
        this.serverTimestamp = newSpareParts.serverTimestamp;

        if (!previousTimestamp) {
          this.ids = newSpareParts.ids;
          this.spareParts = newSpareParts.spareParts;
        } else {
          // overwrite with any incoming spare parts
          for (const id of newSpareParts.ids) {
            const sp = newSpareParts.spareParts[id];
            if (sp) {
              if (!this.ids.includes(sp.inventory_id))
                this.ids.push(sp.inventory_id);
              this.spareParts[id] = sp;
              await sparePartsTable.setItem(`${id}`, clone(sp));
            }
          }
        }

        // sort by part number
        this.ids = this.ids.sort((idA, idB) => {
          const partA: SparePart = this.spareParts[idA];
          const partB: SparePart = this.spareParts[idB];
          return partA.part_number.localeCompare(partB.part_number);
        });
      } catch (error: any) {
        log().error(
          `SparePartsStore.update`,
          `exception`,
          fromException(error)
        );
      } finally {
        this.updating = false;
      }
      await this.persist();
      return this;
    },

    partDescription(id: number): string {
      const part = this.spareParts[id];
      return part ? part.part_description ?? "" : "";
    },

    partManufacturer(id: number): string {
      const part = this.spareParts[id];
      return part ? part.part_manufacturer ?? "" : "";
    },

    partOwner(id: number): number | null | undefined {
      const part = this.spareParts[id];
      return part ? part.part_owner : null;
    },

    partSiteCode(id: number): string {
      const part = this.spareParts[id];
      return part ? part.site_code ?? "" : "";
    },

    onHand(id: number): number {
      const part = this.spareParts[id];
      return part ? part.onhand_quantity ?? 0 : 0;
    },

    /**
     * Adjusts the quantity remaining for spare parts that have been consumed or replaced.
     *
     * @param id The inventory_id of the part type.
     * @param quantity The count being set for the updated quantity.
     */
    async updateOnHand(part: SparePart) {
      const thePart = this.spareParts[part.inventory_id];
      if (thePart) {
        thePart.onhand_quantity = part.onhand_quantity;
        await sparePartsTable.setItem(`${thePart.inventory_id}`, thePart);
      }
    },

    /**
     * Hydrate from LocalStorage
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating SparePartStore: ${this.hydrated} `);

      if (!this.hydrated) {
        const timer = new Timer().start();
        log().debug(`SparePartStore.hydrate`, `start`, undefined, timer.ms());

        const replacementState = new SpareParts();
        await sparePartsTable.iterate((value, key) => {
          if (key === appConfig.serverTimestampKey) {
            replacementState.serverTimestamp = value as string;
          } else {
            const part = value as SparePart;
            const id = Number(key);
            replacementState.ids.push(id);
            replacementState.spareParts[id] = part;
          }
        });
        this.$state.serverTimestamp = replacementState.serverTimestamp;
        this.$state.ids = replacementState.ids;
        this.$state.spareParts = replacementState.spareParts;
        this.$state.hydrated = true;

        log().debug(
          `SparePartStore.hydrate`,
          `${this.ids.length} spare parts restored`,
          undefined,
          timer.stop().ms()
        );
      }
    },

    /**
     * Stringifies and stores the current state of SpareParts in local storage.
     *
     * For rehydration, call the pinia method getSparePartStore().$reset()
     */
    async persist(): Promise<void> {
      await sparePartsTable.setItem(
        appConfig.serverTimestampKey,
        this.serverTimestamp
      );
      for (const key of this.ids) {
        const part = this.spareParts[key];
        try {
          await sparePartsTable.setItem(`${key}`, clone(part));
        } catch (error: unknown) {
          log().info(
            "SparePartStore.persist",
            `error: could not store Spare Part #${key}: ${JSON.stringify(
              part
            )}`,
            error
          );
        }
      }
    },

    /**
     * Removes spare part store state persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      await sparePartsTable.clear();
      this.$state = SpareParts.init();
      await this.persist();
    },
  },
});
