import en from '@/locales/en.json';
import ja_changes from '@/locales/ja.json';
import pt_changes from '@/locales/pt.json';
import { clone } from '@/utils';
import { ref } from 'vue';

import type { Ref } from "vue";
export type MessageSchema = typeof en;
let languageList: "en" | "pt" | "ja";
export type LanguageCode = typeof languageList;

const pt = clone(en);
_override(pt_changes, pt);

const ja = clone(en);
_override(ja_changes, ja);

const i18n: Ref<MessageSchema> = ref(en);

export default i18n;

export const locales: LanguageCode[] = ["en", "pt", "ja"];

export const setLocale = (locale: LanguageCode): MessageSchema => {
  switch (locale) {
    case "pt":
      i18n.value = pt;
      break;
    case "ja":
      i18n.value = ja;
      break;
    default:
      i18n.value = en;
      break;
  }
  return i18n.value;
};

export function lookup(obj: object, key: string): string {
  let value = obj[key as keyof typeof obj] as string;
  if (!value) value = `???${key}???`;
  return value;
}

export function localeDate(date: string): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  if (date && date !== "") {
    if (date.length <= 10) date += "T00:00:00";
    const theDate = new Date(date);
    return theDate.toLocaleDateString(i18n.value.locale.lang, options);
  }
  return "";
}

export function localeTime(date: string): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  if (date && date !== "") {
    const theDate = new Date(date);
    return theDate.toLocaleTimeString(i18n.value.locale.lang, options);
  }
  return "";
}

export function localeDateTime(
  date: string,
  excludeDateIfToday: boolean = false,
  includeSeconds: boolean = false
): string {
  if (date && date !== "") {
    const theDate = new Date(date);
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    if (!excludeDateIfToday || theDate < today) {
      const options: Intl.DateTimeFormatOptions = {
        weekday: undefined,
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: includeSeconds ? "numeric" : undefined,
      };
      return theDate.toLocaleDateString(i18n.value.locale.lang, options);
    } else {
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      return theDate.toLocaleTimeString(i18n.value.locale.lang, options);
    }
  }
  return "";
}

export function formattedTimestamp(date: string): string {
  if (date && date !== "") {
    const theDate = new Date(date);
    return theDate.toISOString().replaceAll("T", " ");
  }
  return "";
}
/**
 * Converts a string (returned by the API) into the corresponding language code.
 *
 * @param languageName As returned on login.
 * @returns The language code; "en" when it cannot be deduced
 */
export function nameToCode(languageName?: string): LanguageCode {
  languageName = languageName ? languageName.toLocaleLowerCase() : "en";
  if (languageName.startsWith("pt") || languageName.startsWith("po"))
    return "pt";
  if (languageName?.startsWith("ja")) return "ja";
  // if (languageName.startsWith("es") || languageName.startsWith("sp"))
  //   return "es";
  return "en";
}

/**
 * Recursively replaces string values in the second structure from the first.
 *
 * @param from The structure to get string values from.
 * @param to The structure to populate with new string values.
 */
function _override(
  from: Record<string, unknown>,
  to: Record<string, unknown>
): void {
  for (const key in from) {
    if (typeof from[key] === "string") {
      to[key] = from[key];
    } else {
      _override(
        from[key] as Record<string, unknown>,
        to[key] as Record<string, unknown>
      );
    }
  }
}
