import appConfig from "@/app.config.json";
import { attachmentsTable, workOrdersTable } from "@/db";
import { SiteCode } from "@/models/Site";
import {
  WorkOrder,
  WorkOrderCollation,
  WorkOrderDateRange,
  WorkOrders,
  WorkOrderState,
  WorkOrderType,
} from "@/models/WorkOrder";
import {
  getAttachmentContent,
  fetchWorkOrders,
  checkCurrentWOs,
} from "@/services/workorder-services";
import { log } from "@/stores/LogStore";
import { getWorkOrderTypeStore } from "@/stores/WorkOrderTypeStore";
import { date, fiveMinuteAlignedCeiling, fromException, now } from "@/utils";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { defineStore } from "pinia";
import { Timer } from "timer-node";

import { getSiteStore } from "./SiteStore";
import { getUserStore } from "./UserStore";

export const getWorkOrderStore = defineStore("WorkOrder", {
  state: (): WorkOrders => {
    return WorkOrders.init();
  },
  getters: {
    /**
     * An accessor for a work order, specified by ID.
     *
     * @param woid - The work order ID.
     * @returns The work order or undefined if not found.
     */
    workOrder(state: WorkOrders): (woid: number) => WorkOrder | undefined {
      return (woid: number) => {
        return state.workOrders[woid];
      };
    },

    /**
     * An accessor for a work order's type, specified by ID.
     *
     * @param woid - The work order ID.
     * @returns The work order type or undefined if not found.
     */
    workOrderType(
      state: WorkOrders
    ): (woid?: number) => WorkOrderType | undefined {
      return (woid?: number): WorkOrderType | undefined => {
        if (woid) {
          const wo = state.workOrders[woid];
          if (wo)
            return getWorkOrderTypeStore().types?.find(
              (type) => type.type === wo?.wo_type
            );
        }
        return undefined;
      };
    },

    /**
     * A convenient way of getting an activity from a work order using IDs.
     *
     * @param woid - The work order ID.
     * @param actid - The work order's activity ID.
     * @returns The activity or undefined if not found.
     */
    activity(state: WorkOrders) {
      return (woid: number, actid: number) => {
        const workOrder = state.workOrders[woid];
        if (workOrder) {
          return workOrder.activities.find((ai) => ai.activityid === actid);
        }
        return undefined;
      };
    },

    workOrderSiteCodes(state: WorkOrders) {
      return (filter: string = ""): string[] => {
        const siteCodes: Set<SiteCode> = new Set<SiteCode>();
        // start with all sites associated with the technician matching the filter
        getSiteStore().siteCodes.forEach((sc) => {
          if (
            filter === "" ||
            sc.toLowerCase().indexOf(filter.toLowerCase()) != -1
          )
            siteCodes.add(sc);
        });
        // add any sites associated with work orders which may not be in the technician's sites
        Object.values(state.workOrders).forEach((wo) => {
          if (
            filter === "" ||
            wo.site_code.toLowerCase().indexOf(filter.toLowerCase()) != -1
          )
            siteCodes.add(wo.site_code);
        });

        return Array.from(siteCodes);
      };
    },

    workOrderStates() {
      return (): WorkOrderState[] => {
        return [
          WorkOrder.SCHEDULED,
          WorkOrder.IN_PROGRESS,
          WorkOrder.PAUSED,
          WorkOrder.COMPLETE,
        ];
      };
    },

    workOrderCollations() {
      return (): WorkOrderCollation[] => {
        return [
          WorkOrder.BY_NUMBER,
          WorkOrder.BY_START,
          WorkOrder.BY_SITE,
          WorkOrder.BY_ASSET,
        ];
      };
    },

    workOrderDateRanges() {
      return (): WorkOrderDateRange[] => {
        return [
          WorkOrder.PAST,
          WorkOrder.TODAY,
          WorkOrder.THIS_WEEK,
          WorkOrder.NEXT_WEEK,
        ];
      };
    },

    workTime() {
      return (
        workOrder: WorkOrderViewModel | undefined | null = undefined
      ): string => {
        let millis = 0;
        if (workOrder && workOrder.timesheet) {
          workOrder.timesheet.forEach((ts) => {
            if (!ts.deleted) {
              // ensure times will be interpreted as UTC
              if (!ts.start_time.endsWith("Z")) ts.start_time += "Z";
              if (ts.end_time && !ts.end_time.endsWith("Z")) ts.end_time += "Z";

              const startDate = new Date(ts.start_time);
              const endDate = ts.end_time
                ? new Date(ts.end_time)
                : new Date(fiveMinuteAlignedCeiling(now()));
              if (endDate > startDate) {
                millis += endDate.getTime() - startDate.getTime();
              }
            }
          });
          let minutes = Math.floor(millis / 60000);
          const hours = Math.floor(minutes / 60);
          minutes %= 60;
          workOrder.workTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
          return workOrder.workTime;
        }
        return "0:00";
      };
    },
  },
  actions: {
    /**
     * Fetches all changed work orders for the current user and updates the store, unless
     * there is an exception during the call.
     *
     * @returns Any work orders that were fetched in the call.
     */
    async update(loggingIn: boolean = false): Promise<WorkOrders> {
      const since = this.ids.length > 0 ? this.serverTimestamp : null;
      const timer = new Timer({ label: "WorkOrderStore.update" }).start();
      let newWorkOrders: WorkOrders = WorkOrders.init();
      try {
        newWorkOrders = await fetchWorkOrders(
          // attempt to fetch all available workorders if none are now loaded
          since,
          date(((7 - date().getDay()) % 7) + 8).toISOString(), // first Monday two weeks from now
          true,
          !loggingIn
        );

        console.log(`${newWorkOrders.ids}`);
        // check current work orders and remove if not relevant (assigned to another user)
        // only to be done when the app is in the assigned to mode
        if (getUserStore().assignedTo) {
          const serverWOs = await checkCurrentWOs(this.ids);
          for (const wo of serverWOs.ids) {
            const newWO = serverWOs.workOrders[wo];
            this.ids = this.ids.filter((wo) => wo !== newWO.woid);
            await workOrdersTable.removeItem(`${wo}`);
          }
        }

        for (const id of newWorkOrders.ids) {
          const newWO = newWorkOrders.workOrders[id];
          console.log(
            `Loading workorder #${id} - ${newWO.wo_number}, ${newWO.current_state}`
          );
          if (newWO.current_state === WorkOrder.DISCARDED) {
            this.ids = this.ids.filter((id) => id !== newWO.woid);
            await workOrdersTable.removeItem(`${id}`);
          } else {
            if (!this.ids.includes(newWO.woid)) this.ids.push(newWO.woid);
            this.workOrders[newWO.woid] = newWO;

            // fetch and locally persist work order attachment content
            const maxDownloadSize = getUserStore().maxAttachmentDownloadSize;
            for (const attachment of newWO.attachments) {
              if (
                attachment.docid &&
                attachment.file_size &&
                attachment.file_size < maxDownloadSize
              ) {
                const response = await getAttachmentContent(attachment.docid);
                if (response.status === 200 && response.payload) {
                  const parts = response.payload.split(";base64,");
                  attachment.file_type = parts[0].split(":")[1];
                  await attachmentsTable.setItem(
                    `${attachment.docid}`,
                    response.payload
                  );
                }
              }
            }

            // fetch and locally persist activity attachment content
            const attachmentKeys = await attachmentsTable.keys();
            for (const activity of newWO.activities) {
              for (const attachment of activity.attachments) {
                if (
                  attachment.docid &&
                  attachment.file_size &&
                  attachment.file_size < maxDownloadSize &&
                  // don't re-download if we already have the attachment
                  !attachmentKeys.includes(`${attachment.docid}`)
                ) {
                  const response = await getAttachmentContent(attachment.docid);
                  if (response.status === 200 && response.payload) {
                    const parts = response.payload.split(";base64,");
                    attachment.file_type = parts[0].split(":")[1];
                    await attachmentsTable.setItem(
                      `${attachment.docid}`,
                      response.payload
                    );
                  }
                }
              }
            }

            await workOrdersTable.setItem(`${id}`, newWO);
          }
        }
        this.serverTimestamp = newWorkOrders.serverTimestamp;
        await workOrdersTable.setItem(
          appConfig.serverTimestampKey,
          newWorkOrders.serverTimestamp
        );
      } catch (err: any) {
        log().error(
          timer.getLabel(),
          `exception`,
          fromException(err),
          timer.ms()
        );
      } finally {
        log().debug(
          timer.getLabel(),
          `complete`,
          newWorkOrders,
          timer.stop().ms()
        );
      }

      return newWorkOrders;
    },

    /**
     * Uses work order data that was persisted in IndexedDB to set this store's
     * state.
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating WorkOrderStore: ${this.hydrated} `);
      if (!this.hydrated) {
        const timer = new Timer().start();
        log().debug(`WorkOrderStore.hydrate`, `start`, undefined, timer.ms());
        const replacementState = new WorkOrders();

        await workOrdersTable.iterate((value, key) => {
          if (key === appConfig.serverTimestampKey) {
            replacementState.serverTimestamp = value as string;
          } else {
            const workOrder = value as WorkOrder;
            const woid = Number(key);
            replacementState.ids.push(woid);
            replacementState.workOrders[woid] = workOrder;
          }
        });
        this.$state.serverTimestamp = replacementState.serverTimestamp;
        this.$state.ids = replacementState.ids;
        this.$state.workOrders = replacementState.workOrders;
        this.$state.hydrated = true;

        log().debug(
          `WorkOrderStore.hydrate`,
          `${this.ids.length} work orders restored`,
          undefined,
          timer.stop().ms()
        );
      }
    },

    /**
     * Deletes all work order and attachments rows persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      const t = new Timer();
      this.$state = WorkOrders.init();
      log().debug(`WorkOrderStore.purge`, `start`, undefined, t.start().ms());
      await workOrdersTable.clear();
      await attachmentsTable.clear();
      log().debug(`WorkOrderStore.purge`, `complete`, undefined, t.stop().ms());
    },

    /**
     * Responds with the state of the {@link completed} field as a boolean.
     *
     * @returns true when the activity is completed.
     */
    isCompleted(woid: number, actid: number | undefined = undefined): boolean {
      if (!actid) {
        const workOrder = this.workOrder(woid);
        return workOrder?.current_state == WorkOrder.COMPLETE;
      } else {
        const activity = this.activity(woid, actid);
        return activity?.completed === 1;
      }
    },
  },
});
