import { SparePart } from "@/models/SparePart";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";

/**
 * The data for a spare part quantity update.
 */
export class SparePartChanges {
  activityid: number;
  inventory_id: number;
  quantity: number; // number consumed; not total
  old_transaction_id?: number;
  woid?: number; // as query param

  constructor(
    activityid: number,
    inventory_id: number,
    quantity: number,
    woid: number,
    transaction_id?: number
  ) {
    this.activityid = activityid;
    this.inventory_id = inventory_id;
    this.quantity = quantity;
    this.woid = woid;
    this.old_transaction_id = transaction_id;
  }

  /**
   * Gathers any changes made to a work order's activities' spare part consumption.
   *
   * @param updated The work order copy that has been opened in the UI.
   * @returns Any differences from the original's activities' spare part use.
   */
  static from(updated: WorkOrderViewModel): SparePartChanges[] {
    const changes: SparePartChanges[] = [];
    updated.activities.forEach((activity: ActivityViewModel) => {
      activity.spare_parts.forEach((sparePart: SparePart) => {
        const formerly: SparePart | undefined =
          activity.snapshot.spare_parts.find(
            (part: SparePart) => part.inventory_id === sparePart.inventory_id
          );
        if (!formerly || formerly.quantity !== sparePart.quantity) {
          changes.push(
            new SparePartChanges(
              activity.activityid,
              sparePart.inventory_id,
              sparePart.quantity ? sparePart.quantity : 0,
              updated.woid,
              formerly?.transaction_id
            )
          );
        }
      });
    });
    return changes;
  }
}
