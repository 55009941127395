import appConfig from "@/app.config.json";
import { sitesTable } from "@/db";
import { Site, Sites } from "@/models/Site";
import { fetchSites } from "@/services/site-services";
import { clone, fromException } from "@/utils";
import { defineStore } from "pinia";
import { Timer } from "timer-node";

import { log } from "./LogStore";

export const getSiteStore = defineStore("Site", {
  state: (): Sites => Sites.init(),
  getters: {},
  actions: {
    /**
     * Fetches all sites for the current user and updates the store, unless
     * there is an exception during the call.
     *
     * @returns This sites store.
     */
    async update(): Promise<Sites> {
      try {
        this.updating = true;

        const newSites = await fetchSites();
        this.serverTimestamp = newSites.serverTimestamp;
        // overwrite with incoming Sites
        this.siteCodes = newSites.siteCodes;
        this.sites = newSites.sites;
      } catch (error: any) {
        log().error(`SiteStore.update`, `exception`, fromException(error));
      } finally {
        this.updating = false;
      }
      await this.persist();
      return this;
    },

    /**
     * Stores the current state of Sites in IndexedDB.
     */
    async persist(): Promise<void> {
      log().debug(`SiteStore.persist`, `write`, Sites.forLog(this.$state));
      await sitesTable.setItem(
        appConfig.serverTimestampKey,
        this.serverTimestamp
      );
      for (const sc of this.siteCodes) {
        const site = this.sites[sc];
        await sitesTable.setItem(sc, clone(site));
      }
    },

    /**
     * Hydrate from IndexedDB
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating SiteStore: ${this.hydrated} `);

      if (!this.hydrated) {
        const timer = new Timer().start();
        log().debug(`SiteStore.hydrate`, `start`, undefined, timer.ms());

        const replacementState = new Sites();
        await sitesTable.iterate((value, key) => {
          if (key === appConfig.serverTimestampKey) {
            replacementState.serverTimestamp = value as string;
          } else {
            const site = value as Site;
            replacementState.siteCodes.push(key);
            replacementState.sites[key] = site;
          }
        });
        this.$state.serverTimestamp = replacementState.serverTimestamp;
        this.$state.siteCodes = replacementState.siteCodes;
        this.$state.sites = replacementState.sites;
        this.$state.hydrated = true;

        log().debug("UserStore.hydrate()", `hydrated from IDB`, this);
      }
    },

    /**
     * Removes site store state persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      await sitesTable.clear();
      this.$state = Sites.init();
      await this.persist();
    },
  },
});
