import { TimesheetEntry } from "@/models/TimesheetEntry";
import { WorkOrder } from "@/models/WorkOrder";
import {
  fiveMinuteAlignedCeiling,
  fiveMinuteAlignedFloor,
  functionallyEqual,
  localDateTime,
  now,
  nowNoSecondsOrMillis,
  removeZulu,
} from "@/utils";
import { v4 as uuidv4 } from "uuid";

/**
 * The local copy of a timesheet entry to be created, displayed and manipulated by the UI.
 *
 * All times, local and UTC, are to the second (no milliseconds) and have no time zone
 * indicators. Their time zones are to be inferred from field names (start_time vs
 * local_start_time).
 */
export class TimesheetEntryViewModel {
  /**
   * id will only be available when returned from the server
   */
  uuid: string;
  id?: number;
  wo_id?: number;
  field_tech?: string;
  start_time: string;
  end_time?: string | null;
  local_start_time: string;
  local_end_time?: string | null;
  recorded_by?: string;
  deleted: boolean = false;
  readonly snapshot: TimesheetEntrySnapshot;

  constructor(
    woid: number | undefined = undefined,
    fieldTech: string | undefined = undefined,
    startTime: string = nowNoSecondsOrMillis(),
    endTime: string | null = null,
    recordedBy: string | undefined = undefined,
    deleted: boolean | undefined = undefined
  ) {
    this.uuid = uuidv4();
    this.wo_id = woid;
    this.field_tech = fieldTech;
    this.start_time = fiveMinuteAlignedFloor(startTime);
    this.end_time = endTime ? fiveMinuteAlignedCeiling(endTime) : "";
    this.local_start_time = localDateTime(this.start_time);
    if (endTime) this.local_end_time = localDateTime(this.end_time);
    if (deleted) this.deleted = deleted;
    this.snapshot = {
      timestamp: now(),
      start_time: this.start_time,
      end_time: this.end_time,
      deleted: this.deleted,
    };
    this.recorded_by = recordedBy;
  }

  static fromTimesheetEntry(t: TimesheetEntry, wo: WorkOrder) {
    const result = new TimesheetEntryViewModel(
      t.wo_id ?? wo.woid,
      t.field_tech,
      t.start_time,
      t.end_time,
      t.recorded_by
    );
    result.uuid = uuidv4();
    result.id = t.id;
    result.local_start_time = localDateTime(t.start_time);
    if (t.end_time) result.local_end_time = localDateTime(t.end_time);
    return result;
  }

  /**
   * Compares the state of the provided TimesheetEntryViewModel with its snapshot
   * in order to detect any change.
   *
   * @param t - The TimesheetEntryViewModel to check
   * @returns True when change has been detected.
   */
  static hasChanged(t: TimesheetEntryViewModel): boolean {
    let changed = false;
    if (
      !functionallyEqual(
        removeZulu(t.start_time),
        removeZulu(t.snapshot.start_time)
      )
    )
      changed = true;
    if (
      !functionallyEqual(
        removeZulu(t.end_time),
        removeZulu(t.snapshot.end_time)
      )
    )
      changed = true;
    if (t.deleted) changed = true;
    return changed;
  }

  /**
   * Returns a string for logging a timesheet entry update.
   *
   * @param tevm - The timesheet entry that is being uploaded.
   * @returns A string that describes the work order's timesheet entry.
   */
  static forLog(tevm: TimesheetEntryViewModel): string {
    return `woid: ${tevm.wo_id} start_time: ${tevm.start_time} end_time: ${
      tevm.end_time
    }${tevm.deleted ? " DELETED" : ""}`;
  }
}

/**
 * Keeps a memento of changeable state for use when reconciling with server updates.
 */
export type TimesheetEntrySnapshot = {
  /**
   * The instant that this snapshot was taken, which may differ from the timestamp
   * of the view model itself. The 'open' VM (and VM clones) have fresher timestamps.
   */
  timestamp: string;
  start_time: string | null;
  end_time: string | null;
  deleted: boolean;
};
