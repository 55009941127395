import { LogEntry } from "@/models/Log";
import { clone } from "@/utils";
import { WorkOrderViewModel } from "../viewmodels/WorkOrderViewModel";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";
import { WorkOrderType } from "../WorkOrder";

/**
 * The current state of the local changes which are currently being worked on
 * ({@link SyncContext['currentWorkOrder']}), ready to be uploaded ({@link SyncContext['queued']}),
 * uploaded but not yet acknowledged ({@link SyncContext['pending'] pending}),
 * {@link SyncContext['acknowledged'] acknowledged}, for changes that have been acknowledged
 * but not yet checked for consistency with freshly fetched data from the server, and
 * finally either {@link SyncContext['synchronized'] synchronized} or {@link SyncContext['failed'] failed}.
 *
 */
export class SyncContext {
  currentWorkOrder: WorkOrderViewModel | undefined | null = undefined;
  currentWorkOrderType: WorkOrderType | undefined | null = undefined;
  currentActivity: ActivityViewModel | undefined | null = undefined;
  queued: WorkOrderViewModel[] = [];
  pending: WorkOrderViewModel[] = [];
  acknowledged: WorkOrderViewModel[] = [];
  failed: WorkOrderViewModel[] = [];
  synchronized: WorkOrderViewModel[] = [];
  syncing: boolean = false;
  syncMessage: string | undefined = undefined;
  online: boolean = true;
  serverTime: string = "";
  lastSync: string = "";
  lastPing: string = "";
  nextSync: string = "";
  lastPeriodicUpdate: string = ""; // when did we last for new sites, new work order types, ...
  lastGC: string = "";
  hydrated?: boolean = false;

  completeWorkOrderInitiated: boolean = false;
  pauseWorkOrderOpen: boolean = false;
  workOrderTimesheetOpen: boolean = false;
  workOrderNewTimesheetOpen: boolean = false;
  workOrderAttachmentsOpen: boolean = false;
  workOrderSparePartsOpen: boolean = false;
  activityAttachmentsOpen: boolean = false;
  activitySparePartsOpen: boolean = false;

  /**
   * In the absence of any other sync data, this is the default state.
   */
  private static readonly DEFAULT: SyncContext = {
    currentWorkOrder: undefined,
    currentWorkOrderType: undefined,
    currentActivity: undefined,
    queued: [],
    pending: [],
    acknowledged: [],
    failed: [],
    synchronized: [],
    syncing: false,
    syncMessage: "",
    online: true,
    serverTime: "",
    lastSync: "",
    lastPing: "",
    nextSync: "",
    lastPeriodicUpdate: "",
    lastGC: "",
    hydrated: false,
    completeWorkOrderInitiated: false,
    pauseWorkOrderOpen: false,
    workOrderTimesheetOpen: false,
    workOrderNewTimesheetOpen: false,
    workOrderAttachmentsOpen: false,
    workOrderSparePartsOpen: false,
    activityAttachmentsOpen: false,
    activitySparePartsOpen: false,
  } as SyncContext;

  static init() {
    return clone(SyncContext.DEFAULT);
  }
}

/**
 * The various objects which can be posted to the managing scope from the sync worker.
 */
export type WorkerNotification = {
  sync?: boolean;
  periodicUpdates?: boolean;
  gc?: boolean;
  lastPing?: string;
  online?: boolean;
  message?: string;
  log?: LogEntry;
};
