import appConfig from "@/app.config.json";
import { getSyncStore } from "@/stores/SyncStore";
import LogView from "@/views/LogView.vue";
import SettingsView from "@/views/SettingsView.vue";
import SyncView from "@/views/SyncView.vue";
import WorkOrdersView from "@/views/WorkOrdersView.vue";
import { createRouter } from "@ionic/vue-router";
import { createWebHashHistory, RouteRecordRaw } from "vue-router";

import ActivityView from "../views/ActivityView.vue";
import WorkOrderView from "../views/WorkOrderView.vue";

/**
 * Defines all navigation routes
 */
const routes: Array<RouteRecordRaw> = [
  {
    name: "home",
    path: "",
    redirect: { name: appConfig.WorkOrdersRoute },
  },
  // {
  //   path: LoginRoute,
  //   name: i18n.value.MainMenu.login,
  //   component: LoginView,
  // },
  {
    name: appConfig.WorkOrdersRoute,
    path: `/${appConfig.WorkOrdersRoute}`,
    component: WorkOrdersView,
  },
  {
    name: appConfig.WorkOrderRoute,
    path: `/${appConfig.WorkOrderRoute}/:workorderid`,
    component: WorkOrderView, //() => import("../views/WorkOrderView.vue"),
  },
  {
    name: appConfig.ActivityRoute,
    path: `/${appConfig.ActivityRoute}/:workorderid/:activityid`,
    component: ActivityView, //() => import("../views/ActivityView.vue"),
  },
  {
    name: appConfig.SyncRoute,
    path: `/${appConfig.SyncRoute}`,
    component: SyncView,
  },
  {
    name: appConfig.SettingsRoute,
    path: `/${appConfig.SettingsRoute}`,
    component: SettingsView,
  },
  {
    name: appConfig.LogRoute,
    path: `/${appConfig.LogRoute}`,
    component: LogView,
  },
  // {
  //   name: "spare_parts",
  //   path: SparePartsRoute,
  //   component: SparePartsView,
  // },
  // logout is handled differently!
  // {
  //   path: "/logout",
  //   name: i18n.value.MainMenu.logout,
  //   component: LogoutView,
  // },
];

/**
 * App router
 *
 * WebHasHistory used as we cannot be certain the web server will resolve routes correctly
 * scrollBehavior ensures view is scrolled to top when navigating to a new route
 */
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() // ( to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded )
  {
    // const toRoute = String(to.name);
    // const fromRoute = String(from.name);
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

/**
 * before resolving to a new route, check that there are no open modals,
 * and close the current work order if navigating to Sync, Settings, ...
 */
router.beforeEach(async (to /*, from*/) => {
  const toRoute = String(to.name);
  // const fromRoute = String(from.name);
  const syncStore = getSyncStore();
  // log().info(
  //   `router.beforeEach`,
  //   `${fromRoute} -> ${toRoute}: isModalOpen = ${syncStore.isModalOpen()}`
  // );
  if (syncStore.isModalOpen()) {
    syncStore.closeModals();
    // stop route transition...
    return false;
    // return { name: fromRoute };
  } else if (
    toRoute !== appConfig.WorkOrderRoute &&
    toRoute !== appConfig.ActivityRoute &&
    getSyncStore().currentWorkOrder
  ) {
    getSyncStore().closeWorkOrder();
  }
  return true;
});

export default router;
