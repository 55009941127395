import appConfig from "@/app.config.json";
import { WorkOrder, WorkOrders, WorkOrderType } from "@/models/WorkOrder";
import {
  HTTPResponse,
  requestHeaders,
  SimpleStringApiResponse,
  timestampFrom,
  toParamFormat,
} from "@/services";
import { log } from "@/stores/LogStore";
import { ActivityChanges } from "@/models/sync/ActivityChanges";
import { WorkOrderChanges } from "@/models/sync/WorkOrderChanges";
import { date, fromException } from "@/utils";
import { AttachmentViewModel } from "@/models/viewmodels/AttachmentViewModel";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import axios, { AxiosRequestConfig } from "axios";
import { Timer } from "timer-node";

/**
 * Fetches work orders for the current user, returning those that have changes
 * since the provided timestamp or, when no timestamp is provided, all work orders.
 *
 * @param since - The timestamp in ISO format; null for all work orders.
 * @param discarded - Whether to include or supress discarded work orders.
 * @param details - Whether to include details or just a summary.
 * @returns The work orders, accompanied by the server's timestamp.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const fetchWorkOrders = async (
  since: string | null = null,
  scheduled_before: string | null = null,
  details: boolean = true,
  discarded: boolean = true,
  scheduled: boolean = true,
  paused: boolean = true,
  complete: boolean = true,
  in_progress: boolean = true,
  draft: boolean = false
): Promise<WorkOrders> => {
  const url = `${appConfig.serviceUrlRoot}/workorders`;
  const timer = new Timer({ label: `GET ${url}` }).start();
  const config: AxiosRequestConfig = {
    method: "get",
    url,
    params: {
      details,
      discarded,
      scheduled,
      paused,
      complete,
      in_progress,
      draft,
      since: toParamFormat(since),
      scheduled_before: toParamFormat(scheduled_before),
      // complete_since: toParamFormat(date().toISOString()),
      complete_since: toParamFormat(since)
    },
    headers: requestHeaders(),
  };
  const results = new WorkOrders();
  try {
    const response = await axios.get<GET_WorkOrdersResponse>(url, config);
    if (response.data?.data.length > 0)
      log().info(
        timer.getLabel(),
        (since ? `(since ${since})` : ``) +
          ` --> ${response.data?.data.length} work order(s)`,
        response,
        timer.stop().ms()
      );
    results.serverTimestamp = timestampFrom(response);
    for (const wo of response.data?.data as WorkOrder[]) {
      results.ids.push(wo.woid);
      if (wo.last_update && !wo.last_update.endsWith("Z"))
        wo.last_update += "Z";
      for (const ts of wo.timesheet) {
        if (ts.start_time && !ts.start_time.endsWith("Z")) ts.start_time += "Z";
        if (ts.end_time && !ts.end_time.endsWith("Z")) ts.end_time += "Z";
      }
      results.workOrders[wo.woid] = wo;
    }
  } catch (e: unknown) {
    log().error(
      timer.getLabel(),
      (since ? `(since ${since})` : ``) + ` --> exception`,
      fromException(e),
      timer.stop().ms()
    );
  }
  return results;
};

/**
 * Fetches work order types.
 *
 * @returns The work order types.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const fetchWorkOrderTypes = async (): Promise<WorkOrderType[]> => {
  const url = `${appConfig.serviceUrlRoot}/wo-types`;
  const timer = new Timer({ label: `GET ${url}` }).start();
  const config: AxiosRequestConfig = {
    method: "get",
    url,
    params: {},
    headers: requestHeaders(),
  };
  const results: WorkOrderType[] = [];
  try {
    const response = await axios.get<GET_WorkOrderTypesResponse>(url, config);
    for (const type of response.data.data) {
      const workOrderType: WorkOrderType = new WorkOrderType(type);
      workOrderType.activityInputs = await fetchActivityInputs(type);
      results.push(workOrderType);
    }
    if (results.length > 0)
      log().info(
        timer.getLabel(),
        ` --> ${response.data?.data.length} work order(s)`,
        response,
        timer.stop().ms()
      );
  } catch (e: unknown) {
    log().error(
      timer.getLabel(),
      `exception`,
      {
        exception: fromException(e),
        config,
      },
      timer.stop().ms()
    );
  }
  return results;
};

/**
 * Fetches work order activity inputs.
 *
 * @returns Possible values for an activity input.
 */
const fetchActivityInputs = async (type: string): Promise<string[]> => {
  const url = `${appConfig.serviceUrlRoot}/wo-activity-inputs`;
  const timer = new Timer({ label: `GET ${url}` }).start();
  const config: AxiosRequestConfig = {
    method: "get",
    url,
    params: { "wo-type": type },
    headers: requestHeaders(),
    validateStatus: (status: number): boolean =>
      status === 404 || (status >= 200 && status < 300),
  };
  let results: string[] = [];
  try {
    const response = await axios.get<GET_ActivityInputsResponse>(url, config);
    if (response.status === 404) return [];
    results = response.data.data;
  } catch (e: unknown) {
    log().error(timer.getLabel(), `exception`, {
      exception: fromException(e),
      config,
    });
  }
  return results;
};

/**
 * Updates the identified work order and returns the update success or failure.
 *
 * @param update - The changes to be made.
 * @returns The update success message (with response status in {@link HTTPResponse}).
 * @throws Any AxiosError thrown from the call
 */
export const updateWorkOrder = async (
  update: WorkOrderChanges
): Promise<HTTPResponse<string>> => {
  const url = `${appConfig.serviceUrlRoot}/workorder`;
  const timer = new Timer({ label: `PUT ${url}` }).start();
  const config: AxiosRequestConfig<WorkOrderChanges> = {
    method: "put",
    url,
    params: {
      id: update.woid,
    },
    headers: requestHeaders(),
    data: { ...update, woid: undefined },
  };
  try {
    const response = await axios.put<SimpleStringApiResponse>(
      url,
      { ...update, woid: undefined },
      config
    );
    return {
      timestamp: timestampFrom(response),
      status: response.status,
      statusText: response.statusText,
      payload: response.data.data,
    };
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      {
        exception: fromException(e),
        config,
      },
      timer.stop().ms()
    );
    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };
  }
};

/**
 * Updates the identified work order activity and returns the update success or failure.
 *
 * @param update - The changes to be made.
 * @returns The update success message (with response status in {@link HTTPResponse}).
 * @throws Any AxiosError thrown from the call
 */
export const updateActivity = async (
  update: ActivityChanges
): Promise<HTTPResponse<string>> => {
  const url = `${appConfig.serviceUrlRoot}/activity`;
  const timer = new Timer({ label: `PUT ${url}` }).start();
  const data = { ...update, woid: undefined };
  data.attachments = undefined;
  const config: AxiosRequestConfig<ActivityChanges> = {
    method: "put",
    url,
    params: {
      woid: update.woid,
    },
    headers: requestHeaders(),
    data,
  };
  try {
    const response = await axios.request<SimpleStringApiResponse>(config);
    return {
      timestamp: timestampFrom(response),
      status: response.status, // response.status === response.data.status_code,
      statusText: response.statusText,
      payload: response.data.data,
    };
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      {
        exception: fromException(e),
        config,
      },
      timer.stop().ms()
    );
    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };
  }
};

/**
 *
 * @param woid - The work order db ID.
 * @param docid - The document db ID.
 * @returns The attachment, base64 encoded.
 */
export const getAttachmentContent = async (
  docid: number | string
): Promise<HTTPResponse<string>> => {
  if (typeof docid === "number") {
    const url = `${appConfig.serviceUrlRoot}/attachment`;
    const timer = new Timer({ label: `GET ${url}` }).start();

    const config: AxiosRequestConfig = {
      method: "get",
      url,
      params: {
        docid,
        base_64: true,
      },
      headers: requestHeaders(),
    };
    try {
      const response = await axios.request<string>(config);
      const contentType = response?.headers["content-type"] ?? "image/jpeg";
      return {
        timestamp: timestampFrom(response),
        status: response.status,
        statusText: response.statusText,
        payload: `data:${contentType};base64,${response.data}`,
      };
    } catch (e: any) {
      log().error(
        timer.getLabel(),
        `exception`,
        {
          exception: fromException(e),
          config,
        },
        timer.stop().ms()
      );
      return {
        timestamp: "",
        status: e.response.status ?? 0,
        statusText: e.response.statusText ?? `${e}`,
      };
    }
  } else {
    return {
      timestamp: "",
      status: 404,
      statusText: `Content for docid = ${docid} not found`,
    };
  }
};

/**
 * Posts a new attachment.
 *
 * @param attachment - The new attachment info.
 * @returns The timesheet entry with its server assigned ID.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const addAttachmentContent = async (
  wo: WorkOrderViewModel,
  attachment: AttachmentViewModel
): Promise<HTTPResponse<POST_AttachmentResponse>> => {
  const url = `${appConfig.serviceUrlRoot}/attachment`;
  const headers = requestHeaders();
  headers["Content-Type"] = "multipart/form-data";
  const timer = new Timer({ label: `PUT ${url}` }).start();
  const config: AxiosRequestConfig = {
    method: "post",
    url,
    params: {
      woid: wo.woid,
      activityid: attachment.activityid,
      filename: attachment.file_name,
    },
    responseType: "blob",
    headers,
  };

  if (attachment.file_name && attachment.content) {
    let base64 = attachment.content;
    if (base64.startsWith("data:")) {
      base64 = base64.slice(base64.indexOf(",") + 1);
    }
    const binary: string = atob(base64);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([array]);

    const formData = new FormData();
    formData.append("file", blob);
    try {
      const response = await axios.post<POST_AttachmentResponse>(
        url,
        formData,
        config
      );
      return {
        timestamp: timestampFrom(response),
        status: response.status,
        statusText: response.statusText,
        payload: response.data,
      };
    } catch (e: any) {
      log().error(
        timer.getLabel(),
        `exception`,
        {
          exception: fromException(e),
          config,
        },
        timer.stop().ms()
      );
      return {
        timestamp: "",
        status: e.response.status ?? 0,
        statusText: e.response.statusText ?? `${e}`,
      };
    }
  } else {
    log().warn(
      timer.getLabel(),
      `cannot upload data for file with no name or no content"`,
      { config },
      timer.stop().ms()
    );

    throw { message: "cannot upload data for file with no name or no content" };
  }
};

/**
 * Deletes attachment content using its ID.
 *
 * @param entry - The timesheet entry.
 * @returns The timesheet entry that has just been deleted.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const deleteAttachmentContent = async (
  wo: WorkOrderViewModel,
  attachment: AttachmentViewModel
): Promise<HTTPResponse<string>> => {
  const url = `${appConfig.serviceUrlRoot}/attachment`;
  const timer = new Timer({ label: `DELETE ${url}` }).start();

  const config: AxiosRequestConfig = {
    method: "delete",
    url,
    params: {
      woid: wo.woid,
      activityid: attachment.activityid,
      docid: attachment.docid,
      filename: attachment.file_name,
    },
    headers: requestHeaders(),
  };
  if (attachment.file_name) {
    try {
      const response = await axios.request<SimpleStringApiResponse>(config);
      return {
        timestamp: timestampFrom(response),
        status: response.status,
        statusText: response.statusText,
        payload: response.data.data,
      };
    } catch (e: any) {
      log().error(
        timer.getLabel(),
        `exception`,
        {
          exception: fromException(e),
          config,
        },
        timer.stop().ms()
      );
      return {
        timestamp: "",
        status: e.response.status ?? 0,
        statusText: e.response.statusText ?? `${e}`,
      };
    }
  } else {
    log().warn(
      timer.getLabel(),
      `cannot delete data for file with no name`,
      {
        config,
      },
      timer.stop().ms()
    );
    throw { message: "cannot delete data for file with no name" };
  }
};

/**
 * Sends current work orders to backend and check if they are relevant or not, if not returns WOs to be removed .
 *
 * @param woids - list of woids 
 * @returns work orders to be removed, accompanied by the server's timestamp.
 * @throws Any AxiosError thrown from the call after logging it.
 */
export const checkCurrentWOs = async (
  woids: any
): Promise<WorkOrders> => {
  const url = `${appConfig.serviceUrlRoot}/check-current-wos`;
  const timer = new Timer({ label: `POST ${url}` }).start();

  const config: AxiosRequestConfig<CheckWorkOrderRequest> = {
    method: "post",
    url,
    // params: {},
    headers: requestHeaders(),
    data: {
      woids
    },
  };
  try {
    const response = await axios.request<GET_WorkOrdersResponse>(config);
    const results = new WorkOrders();
    console.log(response.data.data)
    for (const wo of response.data?.data as WorkOrder[]) {
      results.ids.push(wo.woid);
      results.workOrders[wo.woid] = wo;
    }
    return results;
  } catch (e: any) {
    const results = new WorkOrders();
    log().error(
      timer.getLabel(),
      `exception`,
      {
        exception: fromException(e),
        config,
      },
      timer.stop().ms()
    );
    return results;
  }
};

interface POST_AttachmentResponse {
  status_code: number;
  server_timestamp: string;
  data: { doc_id: number; mime_type: string };
}

/**
 * The structure of JSON data returned from the API server.
 */
export interface GET_WorkOrdersResponse {
  status_code: number;
  server_timestamp: string;
  data: WorkOrder[];
}

/**
 * The structure of JSON data returned from the API server.
 */
interface GET_WorkOrderTypesResponse {
  status_code: number;
  server_timestamp: string;
  data: string[];
}

/**
 * The structure of JSON data returned from the API server.
 */
interface GET_ActivityInputsResponse {
  status_code: number;
  server_timestamp: string;
  data: string[];
}

interface CheckWorkOrderRequest {
  woids : number[] | null
}