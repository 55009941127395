import { filtersTable } from "@/db";
import { SiteCode } from "@/models/Site";
import {
  WorkOrder,
  WorkOrderDateRange,
  WorkOrderFilters,
  WorkOrderState,
  WorkOrderTypeCode,
} from "@/models/WorkOrder";
import { clone, date } from "@/utils";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { defineStore } from "pinia";

import { log } from "./LogStore";

export const getWorkOrderFilterStore = defineStore("WorkOrderFilter", {
  state: (): WorkOrderFilters => WorkOrderFilters.init(),
  getters: {
    workOrderStates() {
      return (): WorkOrderState[] => {
        return [
          WorkOrder.SCHEDULED,
          WorkOrder.IN_PROGRESS,
          WorkOrder.PAUSED,
          WorkOrder.COMPLETE,
        ];
      };
    },

    workOrderDateRanges() {
      return (): WorkOrderDateRange[] => {
        return [
          WorkOrder.PAST,
          WorkOrder.TODAY,
          WorkOrder.THIS_WEEK,
          WorkOrder.NEXT_WEEK,
        ];
      };
    },

    isDrawerOpen() {
      return (drawer: string): boolean => {
        if (drawer === "all") return true;
        else return this.openDrawers.includes(drawer);
      };
    },

    isFiltered() {
      return (): boolean => {
        return (
          this.textFilter != "" ||
          this.hiddenSites.length > 0 ||
          this.hiddenTypes.length > 0 ||
          this.hiddenStates.length > 0 ||
          this.hiddenDateRanges.length > 0 ||
          this.assignedTo != ""
        );
      };
    },

    isInDateRangeFilter() {
      return (wo: WorkOrder | WorkOrderViewModel): boolean => {
        const today = date();
        const tomorrow = date(1);
        const thisWeek = date(((7 - today.getDay()) % 7) + 1); // closest Monday
        const nextWeek = date(((7 - today.getDay()) % 7) + 8); // next closest Monday
        const start = new Date(wo.projected_start);

        return (
          (!this.hiddenDateRanges.includes(WorkOrder.PAST) && start < today) ||
          (!this.hiddenDateRanges.includes(WorkOrder.TODAY) &&
            start >= today &&
            start < tomorrow) ||
          (!this.hiddenDateRanges.includes(WorkOrder.THIS_WEEK) &&
            start >= tomorrow &&
            start < thisWeek) ||
          (!this.hiddenDateRanges.includes(WorkOrder.NEXT_WEEK) &&
            start >= thisWeek &&
            start < nextWeek)
        );
      };
    },

    isSiteHidden() {
      return (site_code: SiteCode): boolean => {
        return this.hiddenSites.length > 0
          ? this.hiddenSites.includes(site_code)
          : false;
      };
    },

    isTypeHidden() {
      return (typeCode: WorkOrderTypeCode): boolean => {
        return this.hiddenTypes.length > 0
          ? this.hiddenTypes.includes(typeCode)
          : false;
      };
    },

    isStateHidden() {
      return (state: WorkOrderState | string): boolean => {
        return this.hiddenStates.length > 0
          ? this.hiddenStates.filter((s) => s.toString() === state.toString())
              .length > 0
          : false;
      };
    },
  },

  actions: {
    resetFilters(): void {
      this.hiddenSites = [];
      this.hiddenStates = [];
      this.textFilter = "";
      this.persist();
    },

    setTextFilter(text: string | null | undefined): void {
      this.textFilter = text ?? "";
      this.persist();
    },

    clearTextFilter(): void {
      this.textFilter = "";
      this.persist();
    },

    toggleGrouped(): void {
      this.grouped = !this.grouped;
      this.persist();
    },

    toggleDrawer(drawer: string): void {
      if (this.openDrawers.includes(drawer))
        this.openDrawers = this.openDrawers.filter((d) => d != drawer);
      else this.openDrawers.push(drawer);
      this.persist();
    },

    setAssignedToFilter(text: string | null | undefined, state: boolean): void {
      if (!state) {
        this.assignedTo = "";
        this.toggle = false;
      } else {
        this.assignedTo = text ?? '';
        this.toggle = true;
      }
      this.persist();
    },

    async updateHiddenDateRanges(range: WorkOrderDateRange, checked: boolean) {
      if (!checked) {
        if (!this.hiddenDateRanges.includes(range)) {
          this.hiddenDateRanges.push(range);
        }
      } else {
        if (this.hiddenDateRanges.includes(range)) {
          this.hiddenDateRanges = this.hiddenDateRanges.filter(
            (dr: WorkOrderDateRange) => dr !== range
          );
        }
      }
      this.persist();
    },

    async updateHiddenSites(site_code: SiteCode, checked: boolean) {
      if (!checked) {
        if (!this.hiddenSites.includes(site_code)) {
          this.hiddenSites.push(site_code);
        }
      } else {
        if (this.hiddenSites.includes(site_code)) {
          this.hiddenSites = this.hiddenSites.filter(
            (sc: SiteCode) => sc !== site_code
          );
        }
      }
      this.persist();
    },

    async updateHiddenTypes(typeCode: WorkOrderTypeCode, checked: boolean) {
      if (!checked) {
        if (!this.hiddenTypes.includes(typeCode)) {
          this.hiddenTypes.push(typeCode);
        }
      } else {
        if (this.hiddenTypes.includes(typeCode)) {
          this.hiddenTypes = this.hiddenTypes.filter(
            (t: WorkOrderTypeCode) => t !== typeCode
          );
        }
      }
      this.persist();
    },

    async updateHiddenStates(state: WorkOrderState, checked: boolean) {
      if (!checked) {
        if (!this.hiddenStates.includes(state)) {
          this.hiddenStates.push(state);
        }
      } else {
        if (this.hiddenStates.includes(state)) {
          this.hiddenStates = this.hiddenStates.filter(
            (t: WorkOrderState) => t !== state
          );
        }
      }
      this.persist();
    },

    /**
     * Stringifies and stores the current state of SpareParts in IndexedDB.
     */
    async persist(): Promise<void> {
      await filtersTable.setItem("hiddenSites", clone(this.hiddenSites));
      await filtersTable.setItem("hiddenTypes", clone(this.hiddenTypes));
      await filtersTable.setItem("hiddenStates", clone(this.hiddenStates));
      await filtersTable.setItem(
        "hiddenDateRanges",
        clone(this.hiddenDateRanges)
      );
      await filtersTable.setItem("textFilter", this.textFilter);
      await filtersTable.setItem("grouped", this.grouped);
      await filtersTable.setItem("openDrawers", clone(this.openDrawers));
      await filtersTable.setItem("toggle", this.toggle);
    },

    /**
     * Hydrate from IndexedDB
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating WorkOrderFilterStore: ${this.hydrated} `);

      if (!this.hydrated) {
        this.$state.hiddenSites =
          (await filtersTable.getItem("hiddenSites")) ?? [];
        this.$state.hiddenTypes =
          (await filtersTable.getItem("hiddenTypes")) ?? [];
        this.$state.hiddenStates =
          (await filtersTable.getItem("hiddenStates")) ?? [];
        this.$state.hiddenDateRanges =
          (await filtersTable.getItem("hiddenDateRanges")) ?? [];
        this.$state.textFilter =
          (await filtersTable.getItem("textFilter")) ?? "";
        this.$state.grouped = (await filtersTable.getItem("grouped")) ?? true;
        this.$state.hiddenSites = (await filtersTable.getItem(
          "openDrawers"
        )) ?? ["current"];
        this.$state.hydrated = true;

        log().debug(
          "WorkOrderFilterStore.hydrate()",
          `hydrated from IndexedDB`,
          this
        );
      }
    },

    /**
     * Removes spare part store state persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      log().debug(`WorkOrderFilters.purge`, ``);
      await filtersTable.clear();
      this.$state = WorkOrderFilters.init();
      await this.persist();
    },
  },
});
