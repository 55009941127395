import { workOrderTypesTable } from "@/db";
import { WorkOrderType, WorkOrderTypes } from "@/models/WorkOrder";
import { fetchWorkOrderTypes } from "@/services/workorder-services";
import { clone } from "@/utils";
import { defineStore } from "pinia";
import { Timer } from "timer-node";

import { log } from "./LogStore";

export const getWorkOrderTypeStore = defineStore("WorkOrderType", {
  state: (): WorkOrderTypes => WorkOrderTypes.init(),
  getters: {},
  actions: {
    async update(): Promise<WorkOrderType[]> {
      const timer = new Timer().start();
      this.types = await fetchWorkOrderTypes();
      if (!this.types || this.types.length === 0) {
        this.types = [];
        this.types.push(new WorkOrderType("CORRECTIVE MAINTENANCE"));
        const pm = new WorkOrderType("PREVENTATIVE MAINTENANCE");
        pm.activityInputs = ["Satisfactory", "Unsatisfactory"];
        this.types.push(pm);
        this.types.push(new WorkOrderType("REMOTE MAINTENANCE"));
      }
      log().debug(
        `WorkOrderTypeStore.update`,
        `complete`,
        undefined,
        timer.stop().ms()
      );
      await this.persist();
      return this.types;
    },

    /**
     * Stringifies and stores the current state of SpareParts in IndexedDB.
     */
    async persist(): Promise<void> {
      log().debug(`WorkOrderTypes.persist`, this.types.toLocaleString());
      for (const type of this.types) {
        await workOrderTypesTable.setItem(type.type, clone(type));
      }
    },

    /**
     * Hydrate from LocalStorage
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating WorkOrderTypeStore: ${this.hydrated} `);

      if (!this.hydrated) {
        const replacementState = new WorkOrderTypes();
        await workOrderTypesTable.iterate((value) => {
          const type = value as WorkOrderType;
          replacementState.types.push(type);
        });
        this.$state.types = replacementState.types;
        this.$state.hydrated = true;

        log().debug("WorkOrderTypeStore.hydrate()", `hydrated from IndexedDB`);
      }
    },

    async purge(): Promise<void> {
      log().debug(`WorkOrderTypes.purge`, ``);
      await workOrderTypesTable.clear();
      this.$state = WorkOrderTypes.init();
      await this.persist();
    },
  },
});
