import { SiteCode } from "@/models/Site";
import { WorkOrder, WorkOrderState } from "@/models/WorkOrder";
import { clone, now } from "@/utils";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";
import { TimesheetEntryViewModel } from "@/models/viewmodels/TimesheetEntryViewModel";
import { v4 as uuidv4 } from "uuid";

import { AttachmentViewModel } from "./AttachmentViewModel";

export class Failure {
  status: number = 200;
  timestamp: string = "";
  message: string = "";
  request: string = "";
}

/**
 * The local copy of work order data to be displayed and manipulated by the UI.
 */
export class WorkOrderViewModel {
  uuid: string = uuidv4();
  woid: number;
  nodeid: number;
  wo_number: string;
  site_code: SiteCode;
  node_name: string;
  description: string;
  special_instructions: string;
  requestor: string;
  wo_type: string;
  assigned_to: string;
  projected_start: string;
  projected_completion: string;
  actual_start: string;
  actual_completion: string;
  site_node_id: number;
  last_updated_by: string;
  current_state: WorkOrderState;
  last_update: string;
  tz_name: string;
  pause_reason: string;
  activities: ActivityViewModel[] = [];
  attachments: AttachmentViewModel[] = [];
  timesheet: TimesheetEntryViewModel[] = [];
  synchronizedAt: string | undefined = undefined;
  failures: Failure[] = [];
  workTime: string = "0:00";

  /**
   * Used to distinguish amongst view model copies when, for example, there
   * are queued or open as well as pending VMs for a single work order ID.
   */
  timestamp: string = now();
  /**
   * What this view model's assignable fields were when it was created.
   */
  readonly snapshot: Snapshot;

  constructor(wo: WorkOrder) {
    this.woid = wo.woid;
    this.nodeid = wo.nodeid;
    this.wo_number = wo.wo_number;
    this.site_code = wo.site_code;
    this.node_name = wo.node_name;
    this.description = wo.description;
    this.special_instructions = wo.special_instructions ?? "";
    this.requestor = wo.requestor;
    this.wo_type = wo.wo_type;
    this.assigned_to = wo.assigned_to;
    this.projected_start = wo.projected_start;
    this.projected_completion = wo.projected_completion;
    this.actual_start = wo.actual_start ?? "";
    this.actual_completion = wo.actual_completion ?? "";
    this.site_node_id = wo.site_node_id;
    this.last_updated_by = wo.last_updated_by;
    this.current_state = wo.current_state;
    this.last_update = wo.last_update;
    this.tz_name = wo.tz_name;
    this.pause_reason = wo.pause_reason ?? "";
    this.activities = wo.activities.map<ActivityViewModel>(
      (activity) => new ActivityViewModel(activity)
    );
    this.attachments = wo.attachments.map<AttachmentViewModel>(
      (attachment) => new AttachmentViewModel(attachment)
    );
    this.timesheet = wo.timesheet.map<TimesheetEntryViewModel>((entry) =>
      TimesheetEntryViewModel.fromTimesheetEntry(entry, wo)
    );
    this.snapshot = {
      timestamp: now(),
      actual_start: wo.actual_start ?? null,
      actual_completion: wo.actual_completion ?? null,
      current_state: wo.current_state,
      pause_reason: wo.pause_reason ?? null,
      timesheet: clone(this.timesheet),
    };
  }
}

/**
 * Keeps a memento of changeable state for use when reconciling with server updates.
 */
export type Snapshot = {
  /**
   * The instant that this snapshot was taken, which may differ from the timestamp
   * of the view model itself. The 'open' VM (and VM clones) have fresher timestamps.
   */
  timestamp: string;
  actual_start: string | null; // date local
  actual_completion: string | null; // date local
  current_state: WorkOrderState | null;
  pause_reason: string | null;
  timesheet: TimesheetEntryViewModel[];
};
