import appConfig from "@/app.config.json";
import { SparePart, SpareParts } from "@/models/SparePart";
import {
  HTTPResponse,
  requestHeaders,
  SimpleStringApiResponse,
  timestampFrom,
  toParamFormat,
} from "@/services";
import { log } from "@/stores/LogStore";
import { SparePartChanges } from "@/models/sync/SparePartChanges";
import { fromException } from "@/utils";
import axios, { AxiosRequestConfig } from "axios";
import { Timer } from "timer-node";

export const fetchSpareParts = async (
  since: string | null
): Promise<SpareParts> => {
  const result = new SpareParts();
  const url = `${appConfig.serviceUrlRoot}/spare-parts`;
  const timer = new Timer({ label: `GET ${url}` }).start();
  const config: AxiosRequestConfig = {
    method: "get",
    url,
    params: {
      since: toParamFormat(since),
    },
    headers: requestHeaders(),
  };

  try {
    const response = await axios.get<GET_SparePartsResponse>(url, config);
    result.serverTimestamp = timestampFrom(response);
    response.data.data.forEach((sp) => {
      result.ids.push(sp.inventory_id);
      result.spareParts[sp.inventory_id] = sp;
    });
    if (result.ids.length > 0)
      log().info(
        timer.getLabel(),
        (since ? `since ${since}` : ``) +
          ` --> ${result.ids.length} spare part inventory items`,
        config,
        timer.stop().ms()
      );
  } catch (e: unknown) {
    log().error(
      timer.getLabel(),
      `exception`,
      { exception: fromException(e), config },
      timer.stop().ms()
    );
  }
  return result;
};

export interface GET_SparePartsResponse {
  status_code: number;
  server_timestamp: string;
  data: SparePart[];
}

/**
 * Sends the number of an identified part that have been consumed for an activity.
 *
 * @param changes - The quantity, inventory ID and activity ID.
 * @returns The update list of transaction IDs.
 * @throws Any AxiosError thrown from the call
 */
export const consumeSpareParts = async (
  changes: SparePartChanges
): Promise<HTTPResponse<{ transaction_id: number }[]>> => {
  const url = `${appConfig.serviceUrlRoot}/spare-parts`;
  const timer = new Timer({ label: `POST ${url}` }).start();
  const config: AxiosRequestConfig<SparePartChanges> = {
    method: "post",
    url,
    params: {
      woid: changes.woid,
    },
    headers: requestHeaders(),
    data: { ...changes, woid: undefined },
  };
  try {
    const response = await axios.post<POST_SparePartConsumption>(
      url,
      { ...changes, woid: undefined },
      config
    );
    return {
      timestamp: timestampFrom(response),
      status: response.status,
      statusText: response.statusText,
      payload: response.data.data,
    };
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      { exception: fromException(e), config },
      timer.stop().ms()
    );
    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };
  }
};

interface POST_SparePartConsumption {
  status_code: number;
  server_timestamp: string;
  data: { transaction_id: number }[];
}

/**
 * Removes the spare parts consumption for the identified work order activity and
 * returns the update success or failure.  Must be called only where a previous
 * transaction ID is contained in the update in order to perform the deletion.
 *
 * @param update - The changes to be made.
 * @returns The delete success message (with response status in {@link HTTPResponse}).
 * @throws Any AxiosError thrown from the call
 */
export const deleteOldSpareParts = async (
  update: SparePartChanges
): Promise<HTTPResponse<string>> => {
  const url = `${appConfig.serviceUrlRoot}/spare-parts`;
  const timer = new Timer({ label: `DELETE ${url}` }).start();
  const config: AxiosRequestConfig<SparePartChanges> = {
    method: "delete",
    url,
    params: {
      woid: update.woid,
      activityid: update.activityid,
      transaction_id: update.old_transaction_id,
    },
    headers: requestHeaders(),
  };
  try {
    const response = await axios.delete<SimpleStringApiResponse>(url, config);
    if (response.status !== 200) {
      log().warn(
        `deleteOldSpareParts`,
        `status: ${response.status} for delete old quantity via transaction_id: ${update.old_transaction_id} of inventory_id: ${update.inventory_id} from woid: ${update.woid} activityid: ${update.activityid}`,
        response
      );
    }
    return {
      timestamp: timestampFrom(response),
      status: response.status,
      statusText: response.statusText,
      payload: response.data.data,
    };
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      { exception: fromException(e), config },
      timer.stop().ms()
    );
    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };
  }
};
