import appConfig from "@/app.config.json";
import { userTable } from "@/db";
import { User } from "@/models/User";
import {
  requestHeaders,
  SimpleStringApiResponse,
  timestampFrom,
} from "@/services";
import { log } from "@/stores/LogStore";
import { fromException } from "@/utils";
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { Timer } from "timer-node";

export class PingResponse {
  timestamp: string = "";
  status: number = 0;
  statusText: string = "";
  duration: number = 0;
}

export class NetworkStrengthResponse extends PingResponse {
  isStrong: boolean = false;
}

export const ping = async (): Promise<PingResponse> => {
  return await pingRequest("get");
};

const STRONG_CONNECTION_SECONDS = 16;

export const testNetworkStrength =
  async (): Promise<NetworkStrengthResponse> => {
    const data = generateJunkData(16);

    // Create a promise that rejects after STRONG_CONNECTION_SECONDS
    const timeoutPromise = new Promise<NetworkStrengthResponse>(
      (resolve, reject) => {
        setTimeout(() => {
          const response = new NetworkStrengthResponse();
          response.timestamp = new Date().toISOString();
          response.statusText = "Request timed out";
          reject(response);
        }, STRONG_CONNECTION_SECONDS * 1000);
      }
    );

    try {
      // Race the pingRequest against the timeout
      const response = await Promise.race([
        pingRequest("patch", { someContent: data }, "/requestping"),
        timeoutPromise,
      ]);

      // If the request completes within the time limit, check the response
      const networkResponse = new NetworkStrengthResponse();
      networkResponse.timestamp =
        response.timestamp || new Date().toISOString();
      networkResponse.status = response.status;
      networkResponse.statusText = response.statusText;
      networkResponse.duration = response.duration;
      networkResponse.isStrong =
        response.status === 200 &&
        networkResponse.duration < STRONG_CONNECTION_SECONDS * 1000;

      return networkResponse;
    } catch (error) {
      // Handle timeout or other errors
      if (error instanceof NetworkStrengthResponse) {
        error.isStrong = false;
        return error;
      }

      const errorResponse = new NetworkStrengthResponse();
      errorResponse.timestamp = new Date().toISOString();
      errorResponse.statusText = (error as Error).message || "Error";
      errorResponse.isStrong = false;
      return errorResponse;
    }
  };

export const pingRequest = async (
  method: string,
  body?: object,
  url: string = "/ping",
  headers: AxiosRequestHeaders = requestHeaders()
): Promise<PingResponse> => {
  const fullUrl = `${appConfig.serviceUrlRoot}${url}`;
  const elapsed = new Timer({
    label: `${method.toUpperCase()} ${fullUrl}`,
  }).start();
  const config: AxiosRequestConfig = {
    method: method,
    url: fullUrl,
    params: {},
    headers,
  };
  if (body) config.data = body;
  try {
    const user = await userTable.getItem<User>(appConfig.userPersistenceKey);
    if (!user?.token) {
      log().debug(
        elapsed.getLabel(),
        `!userStore.token`,
        user,
        elapsed.stop().ms()
      );
      return {
        // EARLY EXIT - not authorized
        timestamp: "",
        status: 500,
        statusText: "User has no JWT",
        duration: elapsed.stop().ms(),
      };
    }
    let apiResponse: AxiosResponse<SimpleStringApiResponse, any>;
    if (method == "patch") {
      apiResponse = await axios.post<SimpleStringApiResponse>(
        fullUrl,
        body,
        config
      );
    } else {
      apiResponse = await axios.get<SimpleStringApiResponse>(fullUrl, config);
    }
    return {
      timestamp: timestampFrom(apiResponse),
      status: apiResponse.status,
      statusText: apiResponse.statusText,
      duration: elapsed.stop().ms(),
    };
  } catch (e: any) {
    log().error(
      elapsed.getLabel(),
      `exception`,
      { exception: fromException(e), config },
      elapsed.stop().ms()
    );
    return {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
      duration: elapsed.stop().ms(),
    };
  }
};

/**
 *
 *  10, //10kb
 *  12, //40kb
 *  13, //80kb
 *  14, //160kb
 *  15, //320kb
 *  16, //640kb
 *  18, //2.5Mb
 *  20 //10Mb
 */
function generateJunkData(repeats: number) {
  let random_data = "abcdefghij"; //10 bytes
  for (let i = 0; i < repeats; i++) {
    random_data += random_data;
  }
  return random_data;
}
