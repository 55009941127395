import { getUserStore } from "@/stores/UserStore";
import { AxiosRequestHeaders, AxiosResponse } from "axios";

/**
 * A container to return the payload and HTTP status to a view.
 */
export interface HTTPResponse<T> {
  timestamp: string;
  status: number;
  statusText: string;
  payload?: T;
}

/**
 * Converts the format of a timestamp from standard ISO to the hyphenless format
 * without milliseconds required for API query parameters.
 *
 * @param since - The UTC timestamp in ISO format.
 * @returns The timestamp without milliseconds, hyphens, colons, or periods;
 * undefined for a null argument.
 */
export function toParamFormat(since: string | null): string | undefined {
  if (since) since = since.replace(/\..*Z$/, "Z");
  return since ? since.replaceAll(/[:-]/g, "") : undefined;
}

export function encodeParam(s: string): string {
  return s.replaceAll(" ", "%20");
}

/**
 * When the body field "server_timestamp" is present, that is returned
 * with the requisite Z suffix. Otherwise, extracts the header named "date"
 * from the response and translates it from conversational American into an
 * ISO timestamp.
 *
 * @param response - The server response with its headers.
 * @returns The ISO string representing server time in UTC; an empty string
 * if nothing can be found.
 */
export function timestampFrom(response: AxiosResponse): string {
  let serverTimestamp = response.data["server_timestamp"];
  if (serverTimestamp && !`${serverTimestamp}`.endsWith("Z"))
    serverTimestamp = `${serverTimestamp}Z`;
  if (!serverTimestamp) {
    const rawDate = response.headers["date"];
    const milliseconds = rawDate ? Date.parse(rawDate) : 0;
    if (milliseconds) serverTimestamp = new Date(milliseconds).toISOString();
  }
  return serverTimestamp ? serverTimestamp : "";
}

/**
 * @returns The request headers with the requisite JWToken
 */
export function requestHeaders(): AxiosRequestHeaders {
  const headers: AxiosRequestHeaders = {} as AxiosRequestHeaders;
  headers["Authorization"] = `JWT ${getUserStore().token}`;
  return headers;
}

/**
 * The structure of JSON data returned from the API server where the body.data
 * returned is just a string.
 */
export interface SimpleStringApiResponse {
  status_code: number;
  server_timestamp: string;
  data: string;
}
