import appConfig from "@/app.config.json";
import {
  attachmentsTable,
  logTable,
  syncContextTable,
  workOrdersTable,
} from "@/db";
import { Activity } from "@/models/Activity";
import { WorkOrder, WorkOrderType } from "@/models/WorkOrder";
import { HTTPResponse } from "@/services";
import { ping, testNetworkStrength } from "@/services/network-services";
import {
  addAttachmentContent,
  deleteAttachmentContent,
  updateActivity,
  updateWorkOrder,
} from "@/services/workorder-services";
import { getWorkOrderStore } from "@/stores/WorkOrderStore";
import { ActivityChanges } from "@/models/sync/ActivityChanges";
import { SparePartChanges } from "@/models/sync/SparePartChanges";
import { SyncContext } from "@/models/sync/SyncContext";
import { WorkOrderChanges } from "@/models/sync/WorkOrderChanges";
import {
  andCatch,
  clone,
  date,
  fiveMinuteAlignedCeiling,
  fiveMinuteAlignedFloor,
  fromException,
  functionallyEqual,
  localDate,
  localDateTime,
  now,
  nowOffset,
  utcFromLocal,
} from "@/utils";
import { ActivityViewModel } from "@/models/viewmodels/ActivityViewModel";
import { AttachmentViewModel } from "@/models/viewmodels/AttachmentViewModel";
import { TimesheetEntryViewModel } from "@/models/viewmodels/TimesheetEntryViewModel";
import {
  Failure,
  WorkOrderViewModel,
} from "@/models/viewmodels/WorkOrderViewModel";
import { defineStore } from "pinia";
import { Timer } from "timer-node";

import { log } from "./LogStore";
import { getSparePartStore } from "./SparePartStore";
import { getUserStore } from "./UserStore";
import { getWorkOrderFilterStore } from "./WorkOrderFilterStore";
import { getSiteStore } from "./SiteStore";
import { getWorkOrderTypeStore } from "./WorkOrderTypeStore";
import { SparePart } from "@/models/SparePart";
import { SyncWorker } from "@/services/sync-manager";

export const getSyncStore = defineStore("SyncContext", {
  state: (): SyncContext => {
    return SyncContext.init();
  },

  getters: {
    // activeWorkOrder(): WorkOrderViewModel | undefined {
    //   return this.currentWorkOrder ?? undefined;
    // },

    // activeActivity(): ActivityViewModel | undefined {
    //   return this.currentActivity ?? undefined;
    // },

    /**
     * A convenient way of getting the number of queued/pending/acknowledged/failed items.
     * @returns The count.
     */
    unsynchronized(state: SyncContext) {
      return (): number => {
        return (
          state.queued.length +
          state.pending.length +
          state.acknowledged.length +
          state.failed.length
        );
      };
    },

    /**
     * A convenient way of getting the number of synchronzied items.
     * @returns The count.
     */
    total(state: SyncContext) {
      return (): number => {
        return (
          state.queued.length +
          state.pending.length +
          state.acknowledged.length +
          state.failed.length +
          state.synchronized.length
        );
      };
    },

    incomplete() {
      return (): number => {
        const workOrderStore = getWorkOrderStore();
        const completed = workOrderStore.ids.filter((woid) => {
          const wo = workOrderStore.workOrders[woid];
          return wo.current_state !== WorkOrder.COMPLETE;
        });
        return completed.length;
      };
    },

    complete() {
      return (): number => {
        const workOrderStore = getWorkOrderStore();
        const completed = workOrderStore.ids.filter((woid) => {
          const wo = workOrderStore.workOrders[woid];
          return wo.current_state === WorkOrder.COMPLETE;
        });
        return completed.length;
      };
    },

    consumedSparePartsCount(state: SyncContext) {
      return (): number => {
        let count = 0;
        if (state.currentWorkOrder) {
          for (const a of state.currentWorkOrder.activities) {
            for (const sp of a.spare_parts) {
              count += sp.quantity ?? 0;
            }
          }
        }
        return count;
      };
    },

    /**
     * A convenient way of getting an activity from the open work order using IDs.
     *
     * @param actid - The work order's activity ID.
     * @returns The activity data or undefined if not found.
     */
    activity(state: SyncContext) {
      return (actid: number): ActivityViewModel | undefined => {
        if (state.currentWorkOrder) {
          return state.currentWorkOrder.activities.find(
            (activity) => activity.activityid === actid
          );
        } else return undefined;
      };
    },

    previousActivity(state: SyncContext) {
      return (): Activity | ActivityViewModel | undefined => {
        if (state.currentWorkOrder && state.currentActivity) {
          let lastActivity;
          for (const activity of state.currentWorkOrder.activities) {
            if (activity.activityid === state.currentActivity.activityid)
              return lastActivity;
            else lastActivity = activity;
          }
        }
        return undefined;
      };
    },

    nextActivity(state: SyncContext) {
      return (): Activity | ActivityViewModel | undefined => {
        if (state.currentWorkOrder && state.currentActivity) {
          let found = false;
          for (const activity of state.currentWorkOrder.activities) {
            if (activity.activityid === state.currentActivity.activityid)
              found = true;
            else if (found) return activity;
          }
        }
        return undefined;
      };

      // return (currentActivityId: number): ActivityViewModel | undefined => {
      //   if (state.currentWorkOrder) {
      //     let found = false;
      //     for (const activity of state.currentWorkOrder.activities) {
      //       if (found && activity.completed !== 1) return activity;
      //       else if (!found && activity.activityid === currentActivityId)
      //         found = true;
      //     }
      //     // double-check, in case there is a incomplete activity before the current activity
      //     if (found) {
      //       for (const activity of state.currentWorkOrder.activities) {
      //         if (
      //           activity.completed !== 1 &&
      //           activity.activityid !== currentActivityId
      //         )
      //           return activity;
      //       }
      //     }
      //   }
      //   return undefined;
      // };
    },

    activitySections(state: SyncContext) {
      return (): string[] => {
        let sections: string[] = [];
        if (state.currentWorkOrder) {
          for (const activity of state.currentWorkOrder.activities) {
            if (!sections.includes(activity.section)) {
              sections = [...sections, activity.section];
            }
          }
        }
        return sections;
      };
    },

    activitySectionNumber(state: SyncContext) {
      return (activity: ActivityViewModel | undefined): number | undefined => {
        if (state.currentWorkOrder && activity) {
          let index = 1;
          for (const section of this.activitySections()) {
            if (section === activity.section) return index;
            index++;
          }
        }
        return undefined;
      };
    },

    /**
     * Returns only work orders which are neither discarded nor draft.
     *
     * @returns Active work orders.
     */
    currentWorkOrders(state: SyncContext) {
      return (): (WorkOrder | WorkOrderViewModel)[] => {
        const timer = new Timer({ label: "currentWorkOrders" }).start();
        try {
          const workOrderStore = getWorkOrderStore();
          const filters = getWorkOrderFilterStore();
          const map = new Map<number, WorkOrder | WorkOrderViewModel>();

          workOrderStore.ids.forEach((woid) => {
            const wo = workOrderStore.workOrders[woid];
            if (
              wo &&
              !filters.isSiteHidden(wo.site_code) &&
              !filters.isStateHidden(wo.current_state) &&
              !filters.isTypeHidden(wo.wo_type) &&
              filters.isInDateRangeFilter(wo) &&
              (filters.assignedTo === "" ||
                `${wo.assigned_to}`
                  ?.toLowerCase()
                  .includes(filters.assignedTo.toLowerCase())) &&
              (filters.textFilter === "" ||
                `${wo.wo_number}<<<${wo.node_name}<<<${wo.description}<<<${wo.assigned_to}`
                  ?.toLowerCase()
                  .includes(filters.textFilter.toLowerCase())) &&
              wo.current_state !== WorkOrder.DISCARDED &&
              wo.current_state !== WorkOrder.DRAFT
            ) {
              map.set(wo.woid, wo);
            }
          });

          state.pending.forEach((wo) => {
            if (map.has(wo.woid)) map.set(wo.woid, wo);
          });
          state.queued.forEach((wo) => {
            if (map.has(wo.woid)) map.set(wo.woid, wo);
          });
          if (state.currentWorkOrder && map.has(state.currentWorkOrder.woid))
            map.set(state.currentWorkOrder.woid, state.currentWorkOrder);

          let results: (WorkOrder | WorkOrderViewModel)[] = [];
          switch (getUserStore().collation) {
            case WorkOrder.BY_SITE:
              results = Array.from(map.values()).sort((aWO, bWO) => {
                if (aWO.site_code === bWO.site_code)
                  return aWO.wo_number < bWO.wo_number ? -1 : 1;
                else return aWO.site_code < bWO.site_code ? -1 : 1;
              });
              break;

            case WorkOrder.BY_ASSET:
              results = Array.from(map.values()).sort((aWO, bWO) => {
                if (aWO.node_name === bWO.node_name)
                  return aWO.wo_number < bWO.wo_number ? -1 : 1;
                else return aWO.node_name < bWO.node_name ? -1 : 1;
              });
              break;

            case WorkOrder.BY_START:
              results = Array.from(map.values()).sort((aWO, bWO) => {
                if (aWO.projected_start === bWO.projected_start)
                  return aWO.wo_number < bWO.wo_number ? -1 : 1;
                else return aWO.projected_start < bWO.projected_start ? -1 : 1;
              });
              break;

            default:
              results = Array.from(map.values()).sort((aWO, bWO) =>
                aWO.wo_number < bWO.wo_number ? -1 : 1
              );
              break;
          }

          // count only includes incomplete work orders
          filters.currentWorkOrderCount = results.filter(
            (wo) => wo.current_state !== WorkOrder.COMPLETE
          ).length;
          return results;
        } finally {
          log().debug(
            `SyncStore.currentWorkOrders`,
            "find active work orders",
            undefined,
            timer.stop().ms()
          );
        }
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    groupedActiveWorkOrders(_state: SyncContext) {
      return (): Map<string, (WorkOrder | WorkOrderViewModel)[]> => {
        const currentWorkOrders = this.currentWorkOrders();
        const timer = new Timer({ label: "groupedActiveWorkOrders" }).start();
        try {
          const today = date();

          const results = new Map<string, (WorkOrder | WorkOrderViewModel)[]>();
          if (getWorkOrderFilterStore().grouped) {
            results.set("overdue", []);
            results.set("current", []);
            results.set("future", []);
            results.set("complete", []);

            currentWorkOrders.forEach((wo) => {
              const projected_start_string = wo.projected_start.replace(
                /-/g,
                "/"
              );
              const projected_completion_string =
                wo.projected_completion != "" && wo.projected_completion != null
                  ? wo.projected_completion.replace(/-/g, "/")
                  : "";

              const start = new Date(projected_start_string);
              const completion = new Date(
                wo.projected_completion != "" && wo.projected_completion != null
                  ? projected_completion_string
                  : projected_start_string
              );
              if (wo.current_state === WorkOrder.COMPLETE)
                results.get("complete")?.push(wo);
              else if (completion < today) results.get("overdue")?.push(wo);
              else if (today < start) results.get("future")?.push(wo);
              else results.get("current")?.push(wo);
            });

            if (results.get("overdue")?.length === 0) results.delete("overdue");
            if (results.get("current")?.length === 0) results.delete("current");
            if (results.get("future")?.length === 0) results.delete("future");
            if (results.get("complete")?.length === 0)
              results.delete("complete");
          } else {
            if (currentWorkOrders.length > 0)
              results.set("all", currentWorkOrders);
          }

          return results;
        } finally {
          log().debug(
            `SyncStore.groupedActiveWorkOrders`,
            "group active work orders",
            undefined,
            timer.stop().ms()
          );
        }
      };
    },

    /**
     * Reveals if there is incomplete synchronization.
     *
     * @returns True when there is some data that may not have synchronized.
     */
    synchronizationIncomplete(state: SyncContext): boolean {
      return (
        !!state.currentWorkOrder ||
        !!state.pending.length ||
        !!state.queued.length ||
        !!state.failed.length
      );
    },

    /**
     * Returns a list of queued, pending and acknowledged work orders, with only the most recent
     * instance for each unique work order
     *
     * @returns List of pending WorkOrderViewModels
     */
    pendingWorkOrders(): WorkOrderViewModel[] {
      const results: WorkOrderViewModel[] = [];
      const foundIds: number[] = [];
      for (let i = this.queued.length - 1; i >= 0; i--) {
        const wo = this.queued[i];
        if (!foundIds.includes(wo.woid)) {
          foundIds.push(wo.woid);
          results.push(wo);
        }
      }
      for (let i = this.pending.length - 1; i >= 0; i--) {
        const wo = this.pending[i];
        if (!foundIds.includes(wo.woid)) {
          foundIds.push(wo.woid);
          results.push(wo);
        }
      }
      for (let i = this.acknowledged.length - 1; i >= 0; i--) {
        const wo = this.acknowledged[i];
        if (!foundIds.includes(wo.woid)) {
          foundIds.push(wo.woid);
          results.push(wo);
        }
      }
      return results;
    },

    /**
     * Returns a list of failed work orders, but only if
     * not found in queued, pending or acknowledged
     *
     * @returns List of synchronized WorkOrderViewModels
     */
    failedWorkOrders(): WorkOrderViewModel[] {
      const results: WorkOrderViewModel[] = [];
      const foundIds: number[] = [];
      for (const wo of this.queued)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);
      for (const wo of this.pending)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);
      for (const wo of this.acknowledged)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);

      for (let i = this.failed.length - 1; i >= 0; i--) {
        const wo = this.failed[i];
        if (!foundIds.includes(wo.woid)) {
          foundIds.push(wo.woid);
          results.push(wo);
        }
      }
      return results;
    },

    /**
     * Returns a list of synchronized work orders, with only the most recent
     * sychronization instance for each unique work order
     *
     * @returns List of synchronized WorkOrderViewModels
     */
    synchronizedWorkOrders(): WorkOrderViewModel[] {
      const results: WorkOrderViewModel[] = [];
      const foundIds: number[] = [];
      for (const wo of this.queued)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);
      for (const wo of this.pending)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);
      for (const wo of this.acknowledged)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);
      for (const wo of this.failed)
        if (!foundIds.includes(wo.woid)) foundIds.push(wo.woid);

      for (let i = this.synchronized.length - 1; i >= 0; i--) {
        const wo = this.synchronized[i];
        if (!foundIds.includes(wo.woid)) {
          foundIds.push(wo.woid);
          results.push(wo);
        }
      }
      return results;
    },

    synchronizedWorkOrderCount(): number {
      return this.synchronizedWorkOrders.length;
    },
  },
  actions: {
    /**
     * Primes a work order copy, specified by ID.
     * Adds the copy to the store if it does not already exist.
     *
     * Note: this may be called repeatedly for the same work order!
     *
     * @param woid - The work order ID.
     * @returns The copy or undefined if the original was not found.
     */
    async openWorkOrder(
      woid: number
    ): Promise<WorkOrderViewModel | undefined | null> {
      // console.log(
      //   `Opening work order #${woid}, existing is ${this.currentWorkOrder?.woid}`
      // );
      const timestamp = now();
      console.log(
        `Opening work order ${woid}, currentWorkOrder id is ${this.currentWorkOrder?.woid}`
      );
      if (this.currentWorkOrder && this.currentWorkOrder.woid !== woid) {
        await this.closeWorkOrder();
      }

      if (!this.currentWorkOrder) {
        // console.log(`Checked if in queued, pending, ...`);

        const queued = this.queued.find((wo) => wo.woid === woid);
        if (queued) {
          this.currentWorkOrder = clone(queued);
          // the duplication will be handled in closeWorkOrder()
        } else {
          const pendingVMs: WorkOrderViewModel[] = this.pending.filter(
            (wo) => wo.woid === woid
          );
          if (pendingVMs.length) {
            // get the most recent
            const pending: WorkOrderViewModel = pendingVMs.reduce(
              (prev, curr) =>
                prev.timestamp.localeCompare(curr.timestamp) > 0 ? prev : curr
            );
            if (pending) {
              this.currentWorkOrder = clone(pending);
            }
          } else {
            const failedVMs: WorkOrderViewModel[] = this.failed.filter(
              (wo) => wo.woid === woid
            );
            if (failedVMs.length) {
              // get the most recent
              const failed: WorkOrderViewModel = failedVMs.reduce(
                (prev, curr) =>
                  prev.timestamp.localeCompare(curr.timestamp) > 0 ? prev : curr
              );
              if (failed) {
                this.currentWorkOrder = clone(failed);
              }
            }
          }
        }
      }

      if (!this.currentWorkOrder) {
        // console.log(`Looking for original work order with id = ${woid} ...`);
        const original = getWorkOrderStore().workOrder(woid);
        if (original) {
          // console.log(`Creating new WorkOrderViewModel for ...`);
          this.currentWorkOrder = new WorkOrderViewModel(original);
        }
      }

      // check if the open work order was completed, but there are now incomplete activities
      // in this case, we change back to the SCHEDULED state. Also, refresh its timestamp.
      if (this.currentWorkOrder) {
        // console.log("WorkOrderViewModel exists!");

        this.currentWorkOrderType = getWorkOrderTypeStore().types?.find(
          (type) => type.type === this.currentWorkOrder?.wo_type
        );

        const incompleteActivities = this.currentWorkOrder.activities.filter(
          (a) => a.completed !== 1
        );
        if (
          incompleteActivities &&
          incompleteActivities.length > 0 &&
          this.currentWorkOrder.current_state === WorkOrder.COMPLETE
        ) {
          this.currentWorkOrder.current_state = WorkOrder.SCHEDULED;
        }
        this.currentWorkOrder.timestamp = timestamp;

        if (appConfig.autoGeneratedTimesheetEntries) {
          // start a new timesheet entry if the work order is IN_PROGRESS
          if (this.currentWorkOrder.current_state === WorkOrder.IN_PROGRESS) {
            // re-open last timesheet entry if the end_time is less than 5 minutes ago
            let lastTimesheetEntry: TimesheetEntryViewModel | undefined =
              undefined;
            for (const entry of this.currentWorkOrder.timesheet) {
              lastTimesheetEntry = entry;
              if (!lastTimesheetEntry.end_time) break;
            }

            if (lastTimesheetEntry) {
              if (lastTimesheetEntry.end_time) {
                if (
                  lastTimesheetEntry.end_time.localeCompare(
                    nowOffset(-appConfig.timesheetEntryOverlap)
                  ) >= 0
                ) {
                  lastTimesheetEntry.end_time = undefined;
                  lastTimesheetEntry.local_end_time = undefined;
                } else {
                  this.currentWorkOrder.timesheet.push(
                    new TimesheetEntryViewModel(this.currentWorkOrder.woid)
                  );
                }
              } else {
                // do nothing, we already have an open timesheet entry
              }
            } else {
              this.currentWorkOrder.timesheet.push(
                new TimesheetEntryViewModel(this.currentWorkOrder.woid)
              );
            }
          }
        }

        // ensure all local* fields are in sync with UTC timestamps
        this.currentWorkOrder.timesheet.forEach((ts) => {
          if (ts.start_time) ts.local_start_time = localDateTime(ts.start_time);
          if (ts.end_time) ts.local_end_time = localDateTime(ts.end_time);
        });

        // MOVED TO WorkOrderAttachmentsButton setOpen
        // load work order attachment content
        // for (const attachment of this.currentWorkOrder.attachments) {
        //   const content = await attachmentsTable.getItem<string>(
        //     `${attachment.docid}`
        //   );
        //   if (content) {
        //     console.log(`Loaded content for ${attachment.docid}`);
        //     attachment.content = content;
        //   }
        // }
        // load activity attachment content

        // MOVED TO ActivityAttachmentsButton setOpen
        // for (const activity of this.currentWorkOrder.activities) {
        //   for (const attachment of activity.attachments) {
        //     // if (!attachment.content) {
        //     const content = await attachmentsTable.getItem<string>(
        //       `${attachment.docid}`
        //     );
        //     if (content) {
        //       console.log(`Loaded content for ${attachment.docid}`);
        //       attachment.content = content;
        //     }
        //     // }
        //   }
        // }
      } else console.log("WorkOrderViewModel does not exist!");

      await this.persist();
      return this.currentWorkOrder;
    },

    /**
     * Primes a work order copy, specified by ID.
     * Adds the copy to the store if it does not already exist.
     *
     * @param woid - The work order ID.
     * @returns The copy or undefined if the original was not found.
     */
    async openActivity(
      woid: number,
      actid: number
    ): Promise<ActivityViewModel | undefined> {
      await this.openWorkOrder(woid);
      this.currentActivity = this.activity(actid);
      log().info(
        `openActivity`,
        `current activity is now #${this.currentActivity?.activityid}`
      );
      await this.persist();
      return this.currentActivity;
    },

    attachContent(docid: number | undefined, content: string | null) {
      if (docid && content && this.currentWorkOrder) {
        for (const at of this.currentWorkOrder.attachments) {
          if (at.docid === docid) {
            at.content = content;
            if (!at.file_type) {
              const parts = content.split(";base64,");
              at.file_type = parts[0].split(":")[1];
            }
            console.log(`Loaded content for ${at.docid} (${at.file_type})`);
          }
        }
      }

      if (docid && content && this.currentActivity) {
        for (const at of this.currentActivity.attachments) {
          if (at.docid === docid) {
            at.content = content;
            if (!at.file_type) {
              const parts = content.split(";base64,");
              at.file_type = parts[0].split(":")[1];
            }
            console.log(`Loaded content for ${at.docid} (${at.file_type})`);
          }
        }
      }
    },

    async updateActivityInput(input: string) {
      if (this.currentActivity) {
        this.currentActivity.input_name = input;
        await this.updateWorkOrderActivity();
      }
    },

    async updateActivityNotes(notes: string | null | undefined) {
      if (this.currentActivity) {
        this.currentActivity.notes = notes ? notes : "";
        await this.updateWorkOrderActivity();
      }
    },

    async updateActivitySpareParts(parts: SparePart[]) {
      if (this.currentActivity) {
        this.currentActivity.spare_parts = parts;
        await this.updateWorkOrderActivity();
      }
    },

    async updateActivitySparePartConsumed(part: SparePart) {
      if (this.currentActivity && part) {
        const thePart = this.currentActivity.spare_parts.find(
          (sp: SparePart) => sp.inventory_id === part.inventory_id
        );
        if (thePart) {
          thePart.quantity = part.quantity;
          thePart.onhand_quantity = part.onhand_quantity;
          await this.updateWorkOrderActivity();
        }
      }
    },

    async consumePart(part: SparePart) {
      if (this.currentActivity) {
        let consumedPart = this.currentActivity.spare_parts.find(
          (sp: SparePart) => sp.inventory_id === part.inventory_id
        );
        if (consumedPart) {
          if (!consumedPart.quantity) consumedPart.quantity = 0;
          consumedPart.quantity++;
          if (consumedPart.onhand_quantity && consumedPart.quantity > 0) {
            consumedPart.onhand_quantity--;
            getSparePartStore().updateOnHand(consumedPart);
          }
        } else if (part.onhand_quantity && part.onhand_quantity > 0) {
          consumedPart = clone(part);
          consumedPart.quantity = 1;
          part.onhand_quantity -= 1;
          getSparePartStore().updateOnHand(part);
          this.currentActivity.spare_parts.push(consumedPart);
        }
        await this.updateWorkOrderActivity();
      }
    },

    async addActivityAttachment(attachment: AttachmentViewModel) {
      if (this.currentActivity) {
        this.currentActivity.attachments.push(attachment);
        await this.updateWorkOrderActivity();
      }
    },

    async deleteActivityAttachment(fileKey: string | undefined) {
      if (fileKey && this.currentActivity) {
        const attachment = this.currentActivity.attachments.find(
          (a: AttachmentViewModel) => a.file_name === fileKey
        );
        if (attachment) {
          attachment.deleted = true;
          await this.updateWorkOrderActivity();
        }
      }
    },

    async restoreActivityAttachment(fileKey: string | undefined) {
      if (fileKey && this.currentActivity) {
        const attachment = this.currentActivity.attachments.find(
          (a: AttachmentViewModel) => a.file_name === fileKey
        );
        if (attachment) {
          attachment.deleted = false;
          await this.updateWorkOrderActivity();
        }
      }
    },

    /**
     * Marks the open activity as completed.
     */
    async completeActivity(): Promise<void> {
      if (this.currentActivity) {
        this.currentActivity.completed = 1;
        await this.updateWorkOrderActivity();
      }
    },

    async markActivityIncomplete(): Promise<void> {
      if (this.currentWorkOrder && this.currentActivity) {
        if (this.currentWorkOrder.current_state === WorkOrder.COMPLETE)
          this.currentWorkOrder.current_state = WorkOrder.IN_PROGRESS;
        this.currentActivity.completed = 0;
        await this.updateWorkOrderActivity();
      }
    },

    /**
     * Calculate the number of completed activities for a work order
     */
    completedActivityCount(wo: WorkOrder | WorkOrderViewModel): number {
      if (!wo.activities) return 0;
      else
        return wo.activities.filter((a) => {
          return a["completed"] === 1;
        }).length;
    },

    /**
     * Calculate the completed activities as a percentage
     * @param wo A work order
     * @returns string
     */
    percentComplete(wo: WorkOrder | WorkOrderViewModel): string {
      if (!wo.activities || wo.activities.length == 0) return "100%";

      const p = Math.trunc(
        100 *
          (wo.activities.length > 0
            ? this.completedActivityCount(wo) / wo.activities.length
            : 0.0) +
          0.5
      );
      return `${p}%`;
    },

    /**
     * Moves the open work order into {@link WorkOrder.IN_PROGRESS}.
     */
    async startWorkOrder(): Promise<void> {
      const workOrder = this.currentWorkOrder;
      if (workOrder && workOrder.current_state === WorkOrder.SCHEDULED) {
        workOrder.pause_reason = "";
        workOrder.actual_start = localDate();
        workOrder.current_state = WorkOrder.IN_PROGRESS;

        // Auto-generated timesheet entries
        if (appConfig.autoGeneratedTimesheetEntries) {
          const floorTime = fiveMinuteAlignedFloor(now());
          const existingTimesheetEntry = workOrder.timesheet.find((ts) => {
            return floorTime.localeCompare(ts.end_time ?? floorTime) < 0
              ? ts
              : null;
          });
          if (existingTimesheetEntry) {
            existingTimesheetEntry.end_time = null;
            existingTimesheetEntry.local_end_time = null;
          } else {
            workOrder.timesheet.push(
              new TimesheetEntryViewModel(workOrder.woid)
            );
          }
        }

        await this.persistCurrent();
      }
    },

    /**
     * Moves the open work order into {@link WorkOrder.PAUSED} state.
     */
    async pauseWorkOrder(reason: string): Promise<void> {
      const workOrder = this.currentWorkOrder;
      if (workOrder && workOrder.current_state === WorkOrder.IN_PROGRESS) {
        workOrder.current_state = WorkOrder.PAUSED;
        workOrder.pause_reason = reason;
        // Auto-generated timesheet entries
        if (appConfig.autoGeneratedTimesheetEntries) {
          workOrder.timesheet.forEach((ts) => {
            if (!ts.end_time) {
              ts.end_time = fiveMinuteAlignedCeiling(now());
              ts.local_end_time = localDateTime(ts.end_time);
            }
          });
        }
        this.pauseWorkOrderOpen = false;
        await this.persistCurrent();
      }
    },

    /**
     * Moves the open work order into {@link WorkOrder.IN_PROGRESS}.
     */
    async resumeWorkOrder(): Promise<void> {
      const workOrder = this.currentWorkOrder;
      if (
        workOrder &&
        (workOrder.current_state === WorkOrder.PAUSED ||
          workOrder.current_state === WorkOrder.COMPLETE)
      ) {
        workOrder.current_state = WorkOrder.IN_PROGRESS;
        workOrder.pause_reason = "";

        // Auto-generated timesheet entries
        if (appConfig.autoGeneratedTimesheetEntries) {
          const floorTime = fiveMinuteAlignedFloor(now());
          const existingTimesheetEntry = workOrder.timesheet.find((ts) => {
            return floorTime.localeCompare(ts.end_time ?? floorTime) < 0
              ? ts
              : null;
          });
          if (existingTimesheetEntry) {
            existingTimesheetEntry.end_time = undefined;
            existingTimesheetEntry.local_end_time = undefined;
          } else {
            workOrder.timesheet.push(
              new TimesheetEntryViewModel(workOrder.woid)
            );
          }
        }
        await this.persistCurrent();
      }
    },
    /**
     * Moves the open work order into {@link WorkOrder.COMPLETE} and sets its
     * actual completion to today.
     */
    async completeWorkOrder(): Promise<void> {
      const at: string = now();
      const workOrder = this.currentWorkOrder;
      if (workOrder && workOrder.current_state !== WorkOrder.COMPLETE) {
        workOrder.actual_completion = at;
        workOrder.current_state = WorkOrder.COMPLETE;
        workOrder.pause_reason = "";
        // Auto-generated timesheet entries
        await this.closeAutoGeneratedTimeSheetEntries();
        await this.persistCurrent();
      }
    },
    /**
     * Ensures all timesheet entries have an end time!
     */
    async closeAutoGeneratedTimeSheetEntries(): Promise<void> {
      const workOrder = this.currentWorkOrder;
      if (workOrder && workOrder.current_state !== WorkOrder.COMPLETE) {
        workOrder.timesheet.forEach((ts) => {
          if (!ts.end_time) {
            ts.end_time = fiveMinuteAlignedCeiling(now());
            ts.local_end_time = localDateTime(ts.end_time);
          }
        });
      }
    },

    async newTimesheetEntry(localStart: string, localEnd: string) {
      if (this.currentWorkOrder) {
        const newEntry = new TimesheetEntryViewModel();
        newEntry.local_start_time = localStart;
        newEntry.start_time = new Date(localStart).toISOString();
        newEntry.local_end_time = localEnd;
        newEntry.end_time = new Date(localEnd).toISOString();
        this.currentWorkOrder.timesheet.push(newEntry);
        await this.sortTimesheet(); // also persists currentWorkOrder
      }
    },

    async sortTimesheet() {
      if (this.currentWorkOrder) {
        // pre-sort entries
        this.currentWorkOrder.timesheet = [
          ...this.currentWorkOrder.timesheet.sort((a, b) => {
            if (a.start_time == b.start_time) {
              if (a.end_time && !b.end_time) return -1;
              else if (b.end_time && !a.end_time) return 1;
              else return (a.end_time ?? "").localeCompare(b.end_time ?? "");
            } else return a.start_time.localeCompare(b.start_time);
          }),
        ];

        // fix any entries ending on a differet date and merge overlapping
        const results: TimesheetEntryViewModel[] = [];
        let lastEntry: TimesheetEntryViewModel | undefined = undefined;

        for (const ts of this.currentWorkOrder.timesheet) {
          if (!ts.deleted) {
            if (ts.local_end_time) {
              const startDate = ts.local_start_time.substring(0, 10);
              const endDate = ts.local_end_time.substring(0, 10);

              // Checking if ts spans day boundaries
              if (startDate.localeCompare(endDate) < 0) {
                ts.end_time = new Date(`${startDate}T23:59:59`).toISOString();
                ts.local_end_time = localDateTime(ts.end_time);
                results.push(ts);
              }
            }
            if (lastEntry) {
              // if (
              //   new Date(ts.start_time).getTime() <
              //   new Date(lastEntry.start_time).getTime()
              // ) {
              //   lastEntry.start_time = ts.start_time;
              // } else
              if (
                lastEntry.end_time &&
                new Date(ts.start_time).getTime() -
                  appConfig.timesheetEntryOverlap * 1_000 <
                  new Date(lastEntry.end_time).getTime()
              ) {
                if (ts.end_time && lastEntry.end_time) {
                  if (
                    new Date(ts.end_time).getTime() >
                    new Date(lastEntry.end_time).getTime()
                  ) {
                    lastEntry.end_time = ts.end_time;
                    lastEntry.local_end_time = ts.local_end_time;
                  }
                }
                ts.deleted = true;
              } else {
                results.push(lastEntry);
                lastEntry = clone(ts);
              }
            } else {
              lastEntry = clone(ts);
            }
          } else {
            results.push(ts);
          }
        }
        if (lastEntry) {
          // console.log(
          //   `Saving timesheet entry: ${lastEntry.start_time} - ${lastEntry.end_time}`
          // );
          results.push(lastEntry);
        }

        // post-sort entries
        this.currentWorkOrder.timesheet = [
          ...results.sort((a, b) => {
            if (a.start_time == b.start_time) {
              if (a.end_time && !b.end_time) return -1;
              else if (b.end_time && !a.end_time) return 1;
              else return (a.end_time ?? "").localeCompare(b.end_time ?? "");
            } else return a.start_time.localeCompare(b.start_time);
          }),
        ];

        await this.persistCurrent();
      }
    },

    async updateTimesheetEntryStart(uuid: string, newLocalStart: string) {
      const ts = this.currentWorkOrder?.timesheet.find((t) => t.uuid === uuid);
      if (ts) {
        ts.local_start_time = newLocalStart;
        ts.start_time = new Date(ts.local_start_time).toISOString();
        if (ts && ts.local_end_time) {
          ts.local_end_time =
            ts.local_start_time.substring(0, 10) +
            ts.local_end_time.substring(10);
          ts.end_time = new Date(ts.local_end_time).toISOString();
          if (ts.local_end_time.localeCompare(ts.local_start_time) < 0) {
            ts.local_end_time = ts.local_start_time;
            ts.end_time = ts.start_time;
          }
        }
        await this.sortTimesheet(); // also persists currentWorkOrder
      }
    },

    async updateTimesheetEntryEnd(uuid: string, newLocalEnd: string) {
      const ts = this.currentWorkOrder?.timesheet.find((t) => t.uuid === uuid);
      if (ts) {
        ts.local_end_time = newLocalEnd;
        ts.end_time = new Date(ts.local_end_time).toISOString();
        if (ts.local_end_time.localeCompare(ts.local_start_time) < 0) {
          ts.local_end_time = ts.local_start_time;
          ts.end_time = ts.start_time;
        }
        // ts.uuid = uuidv4();
        await this.sortTimesheet(); // also persists currentWorkOrder
      }
    },

    async deleteTimesheetEntry(uuid: string) {
      if (this.currentWorkOrder) {
        const ts = this.currentWorkOrder.timesheet.find((t) => t.uuid === uuid);
        if (ts?.end_time) ts.deleted = true;
        await this.persistCurrent();
      }
    },

    async restoreTimesheetEntry(uuid: string) {
      if (this.currentWorkOrder) {
        const ts = this.currentWorkOrder?.timesheet.find(
          (t) => t.uuid === uuid
        );
        if (ts?.deleted) ts.deleted = false;
        await this.persistCurrent();
      }
    },

    isModalOpen(): boolean {
      return (
        this.pauseWorkOrderOpen ||
        this.workOrderTimesheetOpen ||
        this.workOrderNewTimesheetOpen ||
        this.workOrderAttachmentsOpen ||
        this.workOrderSparePartsOpen ||
        this.activityAttachmentsOpen ||
        this.activitySparePartsOpen
      );
    },

    closeModals(): void {
      if (this.workOrderNewTimesheetOpen) {
        this.workOrderNewTimesheetOpen = false;
      } else {
        this.pauseWorkOrderOpen = false;
        this.workOrderTimesheetOpen = false;
        this.workOrderAttachmentsOpen = false;
        this.workOrderSparePartsOpen = false;
        this.activityAttachmentsOpen = false;
        this.activitySparePartsOpen = false;
      }
    },

    /**
     * Moves the open work order to the list of queued work orders.
     */
    async closeWorkOrder(): Promise<void> {
      const closingWorkOrder = this.currentWorkOrder;
      if (closingWorkOrder) {
        await this.closeActivity();
        await this.sortTimesheet();

        // remove existing edits from queue...
        this.queued = this.queued.filter(
          (wo) => wo.woid !== closingWorkOrder?.woid
        );

        let timesheetChanges: boolean = false;
        // close any timesheet entries with missing end dates
        closingWorkOrder.timesheet.forEach((ts) => {
          if (ts.local_start_time)
            ts.start_time = utcFromLocal(ts.local_start_time);
          if (ts.local_end_time) ts.end_time = utcFromLocal(ts.local_end_time);
          if (!ts.end_time) {
            ts.end_time = fiveMinuteAlignedCeiling(now());
          }
        });

        // move to queued if there are changes
        closingWorkOrder.timesheet.forEach((tevm) => {
          if (TimesheetEntryViewModel.hasChanged(tevm)) timesheetChanges = true;
        });

        if (
          timesheetChanges ||
          ActivityChanges.from(closingWorkOrder).length ||
          WorkOrderChanges.from(closingWorkOrder) ||
          SparePartChanges.from(closingWorkOrder).length
        ) {
          log().info(
            "closeWorkOrder",
            `work order ${closingWorkOrder.woid} added to the sync queue`
          );
          this.queued.push(closingWorkOrder);
          this.currentWorkOrder = undefined;
          this.currentWorkOrderType = undefined;
          await this.persist();
          if (this.queued.length > 0 && getUserStore().shouldAutoSync()) {
            this.synchronize();
          }
        } else {
          this.currentWorkOrder = undefined;
          this.currentWorkOrderType = undefined;
          await this.persist();
        }
      }
    },

    /**
     * Moves the open work order to the list of queued work orders.
     */
    async updateWorkOrderActivity(): Promise<void> {
      if (this.currentWorkOrder && this.currentActivity) {
        const theActivity = this.currentWorkOrder.activities.find(
          (a) => a.activityid === this.currentActivity?.activityid
        );
        if (theActivity) {
          theActivity.completed = this.currentActivity.completed;
          theActivity.spare_parts = this.currentActivity.spare_parts;
          theActivity.attachments = this.currentActivity.attachments;
          theActivity.input_name = this.currentActivity.input_name;
          theActivity.notes = this.currentActivity.notes;
        }
        await this.persistCurrent();
      }
    },

    async closeActivity(): Promise<void> {
      await this.updateWorkOrderActivity();
      this.currentActivity = undefined;
    },

    async persistCurrent(): Promise<void> {
      // if (this.currentWorkOrder)
      // if (this.currentActivity)
      //   log().info(
      //     "persistCurrent",
      //     `Saving work order #${this.currentWorkOrder?.woid}, activity #${this.currentActivity.activityid}`
      //   );
      // else
      //   log().info(
      //     "persistCurrent",
      //     `Saving work order #${this.currentWorkOrder?.woid}`
      //   );

      await syncContextTable.setItem(
        "currentWorkOrder",
        clone(this.currentWorkOrder)
      );
      await syncContextTable.setItem(
        "currentWorkOrderType",
        clone(this.currentWorkOrderType)
      );
      await syncContextTable.setItem(
        "currentActivity",
        clone(this.currentActivity)
      );
    },

    /**
     * Stores the current state of the sync context in the database.
     *
     * For rehydration, call the pinia method getSyncContext().$reset()
     */
    async persist(): Promise<void> {
      await syncContextTable.setItem(
        appConfig.serverTimestampKey,
        this.serverTime
      );
      await syncContextTable.setItem("syncing", this.syncing);
      await syncContextTable.setItem("online", this.online);
      await syncContextTable.setItem("lastSync", this.lastSync);
      await syncContextTable.setItem("lastPing", this.lastPing);
      await syncContextTable.setItem("nextSync", this.nextSync);
      await syncContextTable.setItem(
        "lastPeriodicUpdate",
        this.lastPeriodicUpdate
      );
      await syncContextTable.setItem("lastGC", this.lastGC);
      await syncContextTable.setItem("queued", clone(this.queued));
      await syncContextTable.setItem("pending", clone(this.pending));
      await syncContextTable.setItem("acknowledged", clone(this.acknowledged));
      await syncContextTable.setItem("failed", clone(this.failed));
      await syncContextTable.setItem("synchronized", clone(this.synchronized));
      await this.persistCurrent();
    },

    /**
     * Hydrate from IndexedDB
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating SyncStore: ${this.hydrated} `);

      if (!this.hydrated) {
        this.serverTime =
          (await syncContextTable.getItem(appConfig.serverTimestampKey)) ?? "";
        // we should never start as syncing, because it won't let us start actually syncing
        this.syncing = false;
        this.lastSync = (await syncContextTable.getItem("lastSync")) ?? "";
        this.lastPing = (await syncContextTable.getItem("lastPing")) ?? "";
        this.nextSync = (await syncContextTable.getItem("nextSync")) ?? "";
        this.lastPeriodicUpdate =
          (await syncContextTable.getItem("lastPeriodicUpdate")) ?? "";
        this.lastGC = (await syncContextTable.getItem("lastGC")) ?? "";
        this.queued = (await syncContextTable.getItem("queued")) ?? [];
        this.pending = (await syncContextTable.getItem("pending")) ?? [];
        this.acknowledged =
          (await syncContextTable.getItem("acknowledged")) ?? [];
        this.failed = (await syncContextTable.getItem("failed")) ?? [];
        this.synchronized =
          (await syncContextTable.getItem("synchronized")) ?? [];

        this.currentWorkOrder =
          await syncContextTable.getItem<WorkOrderViewModel>(
            "currentWorkOrder"
          );
        this.currentWorkOrderType =
          await syncContextTable.getItem<WorkOrderType>("currentWorkOrderType");
        this.currentActivity =
          await syncContextTable.getItem<ActivityViewModel>("currentActivity");
        this.hydrated = true;

        // if there was a work order open when the app closed, save any changes to the queue
        if (this.currentWorkOrder) {
          log().info(
            "SyncStore.hydrate()",
            `current work order ${this.currentWorkOrder.woid} closed on hydration`
          );
          await this.closeWorkOrder();
        }
        log().info("SyncStore.hydrate()", `hydrated from IndexedDB`, this);
      }
    },

    /**
     * Removes persisted sync store state and resets it.
     */
    async purge(): Promise<void> {
      log().debug(`SyncStore.purge`, `state`, {
        currentWorkOrder: clone(this.currentWorkOrder),
        currentActivity: clone(this.currentWorkOrder),
        queued: clone(this.queued),
        pending: clone(this.pending),
        acknowledged: clone(this.acknowledged),
        failed: clone(this.failed),
        synchronized: clone(this.synchronized),
        syncing: this.syncing,
      });
      this.$state = SyncContext.init();
      await this.persist();
    },

    async setSyncing(syncing: boolean) {
      this.syncing = syncing;
      await syncContextTable.setItem("syncing", syncing);
    },

    async updateLastPing(lastPing?: string) {
      if (lastPing) {
        this.lastPing = lastPing;
        this.serverTime = lastPing;
        await syncContextTable.setItem(appConfig.serverTimestampKey, lastPing);
        await syncContextTable.setItem("lastPing", lastPing);
      }
    },

    async updateOnline(online: boolean) {
      this.online = online;
      await syncContextTable.setItem("online", online);
    },

    async updateLastPeriodicCheck() {
      this.lastPeriodicUpdate = now();
      await syncContextTable.setItem(
        "lastPeriodicUpdate",
        this.lastPeriodicUpdate
      );
    },

    async ping() {
      try {
        const pingResults = await ping();
        await this.updateLastPing(pingResults.timestamp);
        await this.updateOnline(
          pingResults && pingResults.status >= 200 && pingResults.status < 300
        );
      } catch (error: unknown) {
        await this.updateOnline(false);
      }
    },

    async testNetworkStrength() {
      try {
        const pingResults = await ping();
        const testResults = await testNetworkStrength();
        await this.updateLastPing(pingResults.timestamp);
        const isOnline =
          pingResults && pingResults.status >= 200 && pingResults.status < 300;
        await this.updateOnline(isOnline && testResults.isStrong);
        // pause the auto sync worker if we are online but slow
        if (isOnline && !testResults.isStrong) {
          SyncWorker.tempPause();
        }
      } catch (error: unknown) {
        await this.updateOnline(false);
      }
    },

    /**
     * Checks for new sites, new work order types,
     * and runs any garbage collectors
     */
    async periodicUpdates(): Promise<void> {
      await getSiteStore().update();
      await getWorkOrderTypeStore().update();
      await getSparePartStore().update();
      await this.updateLastPeriodicCheck();
    },

    /**
     * Uploads any detected changes in work orders and their activities, spare
     * parts, and timesheets.  Compares subsequently fetched results with what
     * was uploaded in order to assure consistency, logging any differences.
     */
    async synchronize(loggingIn: boolean = false): Promise<void> {
      const timer = new Timer({ label: "synchronize" }).start();
      const user = getUserStore();

      // upload changes
      const workCount = this.queued.length + this.failed.length;
      try {
        if (!this.syncing) {
          await this.setSyncing(true);
          if (!this.online) {
            // we were offline. Test signal strength to see if we truly are online
            await this.testNetworkStrength();
          } else {
            // else we need to ping to make sure we still are online
            await this.ping();
          }
          if (this.online) {
            // this should never be the case
            if (this.pending.length > 0) {
              this.queued = [...this.queued, ...this.pending];
              this.pending = [];
            }

            // remove synchronized items from previous days
            const today = date().toISOString();
            this.synchronized = this.synchronized.filter((wo) => {
              if (wo.synchronizedAt)
                return wo.synchronizedAt.localeCompare(today) >= 0;
              else if (wo.actual_completion)
                return wo.actual_completion.localeCompare(today) >= 0;
              else return wo.projected_start.localeCompare(today) >= 0;
            });

            await this.persist();
            log().debug(timer.getLabel(), `sychronizing`);

            // try again with previous sync failures
            const previousFailures: WorkOrderViewModel[] = [];
            previousFailures.push(...this.failed);
            this.failed.length = 0;
            this.syncMessage = `Sending work order changes`;

            await this._updateChangesIn(previousFailures);

            // update changes in the queued list
            await this._updateChangesIn(this.queued);

            if (this.acknowledged.length) {
              log().debug(
                timer.getLabel(),
                `${this.acknowledged.length} changed work order(s) need to be compared with server update results`,
                this.acknowledged
              );
            }
            await this.persist();

            this.syncMessage = `Loading work order updates`;
            const latestWOState = await getWorkOrderStore().update(loggingIn);
            if (
              this.currentWorkOrder &&
              latestWOState.ids.includes(this.currentWorkOrder.woid)
            ) {
              // update the currentWorkOrder viewmodel with state from the newly returned WO
              const latestCurrentWorkOrder =
                latestWOState.workOrders[this.currentWorkOrder.woid];
              if (latestCurrentWorkOrder) {
                this.currentWorkOrder.assigned_to =
                  latestCurrentWorkOrder.assigned_to;
                this.currentWorkOrder.attachments =
                  latestCurrentWorkOrder.attachments.map<AttachmentViewModel>(
                    (attachment) => new AttachmentViewModel(attachment)
                  );
                this.currentWorkOrder.description =
                  latestCurrentWorkOrder.description;
                this.currentWorkOrder.node_name =
                  latestCurrentWorkOrder.node_name;
                this.currentWorkOrder.nodeid = latestCurrentWorkOrder.nodeid;
                this.currentWorkOrder.projected_completion =
                  latestCurrentWorkOrder.projected_completion;
                this.currentWorkOrder.projected_start =
                  latestCurrentWorkOrder.projected_start;
                this.currentWorkOrder.requestor =
                  latestCurrentWorkOrder.requestor;
                this.currentWorkOrder.site_code =
                  latestCurrentWorkOrder.site_code;
                this.currentWorkOrder.site_node_id =
                  latestCurrentWorkOrder.site_node_id;
                this.currentWorkOrder.special_instructions =
                  latestCurrentWorkOrder.special_instructions ?? "";
                this.currentWorkOrder.tz_name = latestCurrentWorkOrder.tz_name;
                this.currentWorkOrder.wo_number =
                  latestCurrentWorkOrder.wo_number;
                this.currentWorkOrder.wo_type = latestCurrentWorkOrder.wo_type;
                this.currentWorkOrder.workTime =
                  latestCurrentWorkOrder.workTime;

                this.currentWorkOrder.activities.forEach((activity) => {
                  const updatedActivity =
                    latestCurrentWorkOrder.activities.find(
                      (act) => act.activityid === activity.activityid
                    );
                  if (updatedActivity) {
                    activity.activity_description =
                      updatedActivity.activity_description;
                    activity.required_action = updatedActivity.required_action;
                    activity.section = updatedActivity.section;
                    activity.node_name = updatedActivity.node_name;
                    activity.node_ref = updatedActivity.node_ref;
                  }
                });
              }
            }

            this.syncMessage = `Loading spare part updates`;
            getSparePartStore().update();

            // report on inconsistencies in successful calls
            this.syncMessage = `Reconciling changes`;
            this._detectDiscrepancies();
          }
        }
      } catch (err: any) {
        log().error(
          timer.getLabel(),
          `exception`,
          { exception: fromException(err) },
          timer.ms()
        );
      } finally {
        this.syncMessage = undefined;
        this.lastSync = now();

        // subtract the time so the next auto-sync will be exactly one sync interval from last sync
        const syncTimeInSeconds = Math.ceil(timer.ms() / 1000);
        this.nextSync = nowOffset(
          (user?.syncInterval
            ? user.syncInterval
            : appConfig.defaultSyncInterval) - syncTimeInSeconds
        );
        await this.setSyncing(false);
        await this.persist();

        if (this.failed.length) {
          // confirm we are either online or offline
          await this.ping();
          log().warn(
            timer.getLabel(),
            `${this.failed.length} work order update failure(s)`,
            this.failed
          );
        }
        if (workCount > 0) {
          log().info(
            timer.getLabel(),
            `synchronized ${workCount} work orders`,
            this.synchronized,
            timer.stop().ms()
          );
        }
      }
    },

    /**
     * Removes attachments no longer referenced
     */
    async gc(): Promise<void> {
      const timer = new Timer().start();

      try {
        // remove synchronized work orders before today
        const today = date();
        const retained: WorkOrderViewModel[] = [];
        for (const node of this.synchronized) {
          if (!node.synchronizedAt || new Date(node.synchronizedAt) > today) {
            retained.push(node);
          }
        }
        this.synchronized = [...retained];
      } catch (err: unknown) {
        log().error(
          `gc`,
          `error cleaning up synchronized work orders before today`,
          fromException(err)
        );
      }

      try {
        // scan for referenced attachments
        const docids: Set<string> = new Set<string>();
        for (const key of await workOrdersTable.keys()) {
          const workOrder: WorkOrder = (await workOrdersTable.getItem(
            key
          )) as WorkOrder;
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        for (const workOrder of this.queued) {
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        for (const workOrder of this.pending) {
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        for (const workOrder of this.acknowledged) {
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        for (const workOrder of this.failed) {
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        for (const workOrder of this.synchronized) {
          collectDocids(workOrder).forEach((n) => docids.add(n));
        }
        // remove unreferenced attachments
        for (const key of await attachmentsTable.keys()) {
          if (!docids.has(key)) await attachmentsTable.removeItem(key);
        }
      } catch (err: any) {
        log().error(
          `gc`,
          `error removing unreferenced attachments`,
          fromException(err),
          timer.ms()
        );
      }

      try {
        // remove log entries more than 4 hours old
        const d = nowOffset(-4 * 60 * 60);
        for (const key of await logTable.keys()) {
          const ld = key.substring(0, key.indexOf("|"));
          if (ld.localeCompare(d) < 0) await logTable.removeItem(key);
        }
      } catch (err: any) {
        log().error(
          `gc`,
          `error removing log entries more than 4 hours old`,
          fromException(err),
          timer.ms()
        );
      }

      this.persist();
      log().info(`gc`, `complete`, null, timer.stop().ms());
    },

    async _updateChangesIn(collection: WorkOrderViewModel[]): Promise<void> {
      while (collection.length > 0) {
        let updateFailure: boolean = false;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const updated = collection.shift()!;
        this.pending.push(updated);
        await this.persist();

        log().debug("_updateChanges in", `Synchronizing ${updated.wo_number}`);
        this.syncMessage = `Synchronizing ${updated.wo_number}`;
        const previousFailures: Failure[] = clone(updated.failures);

        // make any Activity changes
        const activityChangesList: ActivityChanges[] =
          ActivityChanges.from(updated);
        for (const activityChanges of activityChangesList) {
          // console.log(JSON.stringify(activityChanges, null, 2));
          log().debug(`synchronize`, `ActivityChanges`, activityChanges);
          const [err, res] = await andCatch(updateActivity(activityChanges));
          const response = res as HTTPResponse<string>;
          if (err) {
            log().error(`_updateChangesIn`, `updateActivity exception`, {
              activityChanges,
              exception: fromException(err),
            });
            updated.failures.push({
              status: _extractStatus(err),
              timestamp: now(),
              message: _extractMessage(err),
              request: `update activity: ${JSON.stringify(activityChanges)}`,
            });
            updateFailure = true;
          } else {
            log().debug(
              `_updateChangesIn`,
              `updateActivity ${response.status} ${response.statusText}`,
              { activityChanges, response }
            );
            if (response.status !== 200) {
              updateFailure = true;
              updated.failures.push({
                status: response.status,
                timestamp: response.timestamp,
                message: response.payload ?? response.statusText,
                request: `update activity: ${JSON.stringify(activityChanges)}`,
              });
            }
          }

          // update activityChanges.attachments
          if (activityChanges.attachments) {
            for (const attachment of activityChanges.attachments) {
              // docid will be a number when loaded from the server
              if (typeof attachment.docid === "number" && attachment.deleted) {
                try {
                  await deleteAttachmentContent(updated, attachment);
                } catch (err) {
                  log().error(`Error deleting attachment`, `exception`, err);
                }
              } else if (
                typeof attachment.docid !== "number" &&
                !attachment.deleted
              ) {
                try {
                  await addAttachmentContent(updated, attachment);
                } catch (err) {
                  log().error(`Error adding attachment`, `exception`, err);
                }
              }
            }
          }
        }

        // make any Work Order changes
        const woChanges: WorkOrderChanges | null =
          WorkOrderChanges.from(updated);
        // if any part of the work order was changed, set its last_update via updateWorkOrder'

        if (woChanges) {
          // console.log(JSON.stringify(woChanges, null, 2));
          log().debug(`_updateChangesIn`, `WorkOrderChanges`, woChanges);
          const [err, res] = await andCatch(updateWorkOrder(woChanges));
          const response = res as HTTPResponse<string>;
          if (err) {
            log().error(`_updateChangesIn`, `updateWorkOrder exception`, {
              woChanges,
              exception: fromException(err),
            });
            updated.failures.push({
              status: _extractStatus(err),
              timestamp: now(),
              message: _extractMessage(err),
              request: `update work order: ${JSON.stringify(woChanges)}`,
            });
            updateFailure = true;
          } else {
            log().debug(
              `_updateChangesIn`,
              `updateWorkOrder ${response.status} ${response.statusText}`,
              { woChanges, response }
            );
            if (response.status !== 200) {
              updateFailure = true;
              updated.failures.push({
                status: response.status,
                timestamp: response.timestamp,
                message: `${response.payload}`,
                request: `update work order: ${JSON.stringify(woChanges)}`,
              });
            }
          }
        }

        if (woChanges || activityChangesList.length > 0) {
          if (!updateFailure) {
            this.acknowledged.push(updated);
          } else {
            const popped = this.pending.pop();
            if (popped) {
              this.failed.push(popped); // Always add to failed array
              log().error(
                `_updateChangesIn`,
                `repeated failures updating`,
                clone(popped)
              );
            } else {
              this.syncMessage = `An unexpected internal error has occurred during synchronization.`;
            }
          }
        }
        this.pending = this.pending.filter((vm) => vm.woid !== updated.woid);
        updated.synchronizedAt = now();
      }
    },

    async _detectDiscrepancies(): Promise<void> {
      while (this.acknowledged.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const acknowledged = this.acknowledged.shift()!;
        const fresh = await workOrdersTable.getItem<WorkOrder>(
          `${acknowledged.woid}`
        );
        if (!fresh) {
          log().error(
            `WorkOrder discrepancy`,
            `cannot load updated work order from db`
          );
          return; // EARLY EXIT
        }

        // detect diffs in timesheet entries
        // _checkTimesheets(acknowledged, fresh);

        // confirm spare part quantities
        _checkSparePartQuantities(acknowledged, fresh);

        // work order activities
        _checkWorkOrderActivities(acknowledged, fresh);

        // work order
        _checkWorkOrder(acknowledged, fresh);

        // remove all attachment content for synchronized items
        for (const attachment of acknowledged.attachments)
          attachment.content = undefined;
        for (const activity of acknowledged.activities)
          for (const attachment of activity.attachments)
            attachment.content = undefined;
        this.synchronized.push(acknowledged);
      }
    },
  },
});

function collectDocids(wo: WorkOrder | WorkOrderViewModel): Set<string> {
  const docids: Set<string> = new Set<string>();
  // Work Order Attachments
  if (wo.attachments)
    for (const attachment of wo.attachments)
      if (attachment.docid) docids.add(`${attachment.docid}`);
  //Activity Attachments
  if (wo.activities)
    for (const activity of wo.activities)
      if (activity.attachments)
        for (const attachment of activity.attachments)
          if (attachment.docid) docids.add(`${attachment.docid}`);
  return docids;
}

// async function requestHeaders(): Promise<AxiosRequestHeaders> {
//   const headers: AxiosRequestHeaders = {} as AxiosRequestHeaders;
//   const user = await userTable.getItem<User>(USER_PERSISTENCE_KEY);
//   if (user) headers["Authorization"] = `JWT ${user.token}`;
//   else headers["Authorization"] = `JWT ${userClone?.token}`;
//   return headers;
// }

function _extractStatus(err: unknown): number {
  const error = err as Record<string, any>;
  if (error.status) return Number(error.status);
  else if (error.response?.status) return Number(error.response.status);
  else return 499;
}

function _extractMessage(err: unknown): string {
  if (typeof err === "string") return err;
  const error = err as Record<string, any>;
  if (error.message) return `${error["message"]}`;
  if (error.statusText) return `${error["statusText"]}`;
  if (error.response) {
    const response = error["response"];
    if (response.statusText) return `${response.stausText}`;
    else return "unknown error";
  } else return "unknown error";
}

const _checkSparePartQuantities = (
  acknowledged: WorkOrderViewModel,
  fresh: WorkOrder
) => {
  const sparePartChangesList: SparePartChanges[] =
    SparePartChanges.from(acknowledged);
  for (const changes of sparePartChangesList) {
    const freshActivity = fresh?.activities.find(
      (activity) => activity.activityid === changes.activityid
    );
    const freshPart = freshActivity?.spare_parts.find(
      (part) => part.inventory_id === changes.inventory_id
    );
    if (freshPart?.quantity !== changes.quantity && freshActivity) {
      _reportDiscrepancy(
        acknowledged.wo_number,
        freshActivity.activity_description,
        `inventory id '${changes.inventory_id}' quantity`,
        `${changes.quantity}`,
        `${freshPart?.quantity}`
      );
    }
  }
};

const _checkWorkOrderActivities = (
  acknowledged: WorkOrderViewModel,
  fresh: WorkOrder
) => {
  const activityChangesList: ActivityChanges[] =
    ActivityChanges.from(acknowledged);
  for (const changes of activityChangesList) {
    const freshActivity = fresh?.activities.find(
      (activity) => activity.activityid === changes.activityid
    );
    if (!!freshActivity?.completed !== !!changes.completed) {
      _reportDiscrepancy(
        acknowledged.wo_number,
        freshActivity?.activity_description,
        "completed",
        `${!!changes.completed}`,
        `${!!freshActivity?.completed}`
      );
    }
    if (!functionallyEqual(freshActivity?.input_name, changes.input_name)) {
      _reportDiscrepancy(
        acknowledged.wo_number,
        freshActivity?.activity_description,
        "input",
        `${changes.input_name}`,
        `${freshActivity?.input_name}`
      );
    }
    if (!functionallyEqual(freshActivity?.notes, changes.notes)) {
      _reportDiscrepancy(
        acknowledged.wo_number,
        freshActivity?.activity_description,
        "notes",
        `'${changes.notes}'`,
        `'${freshActivity?.notes}'`
      );
    }
  }
};

const _checkWorkOrder = (
  acknowledged: WorkOrderViewModel,
  fresh: WorkOrder
) => {
  if (!functionallyEqual(fresh?.actual_start, acknowledged.actual_start)) {
    _reportDiscrepancy(
      acknowledged.wo_number,
      undefined,
      "actual start",
      `${acknowledged.actual_start}`,
      `${fresh?.actual_start}`
    );
  }
  if (
    !functionallyEqual(fresh?.actual_completion, acknowledged.actual_completion)
  ) {
    _reportDiscrepancy(
      acknowledged.wo_number,
      undefined,
      "actual completion",
      `${acknowledged.actual_completion}`,
      `${fresh?.actual_completion}`
    );
  }
  if (!functionallyEqual(fresh?.current_state, acknowledged.current_state)) {
    _reportDiscrepancy(
      acknowledged.wo_number,
      undefined,
      "current state",
      `${acknowledged.current_state}`,
      `${fresh?.current_state}`
    );
  }
  if (!functionallyEqual(fresh?.pause_reason, acknowledged.pause_reason)) {
    _reportDiscrepancy(
      acknowledged.wo_number,
      undefined,
      "pause reason",
      `'${acknowledged.pause_reason}'`,
      `'${fresh?.pause_reason}'`
    );
  }
};

function _reportDiscrepancy(
  woNumber: string,
  activityDescription: string | undefined,
  fieldName: string,
  changedTo: string,
  serverShows: string
): void {
  const activityString = activityDescription
    ? ` activity '${activityDescription}'`
    : "";
  log().warn(
    `WorkOrder discrepancy!`,
    `For work order '${woNumber}'${activityString}, ${fieldName} was set to ${changedTo} but server shows ${serverShows}.`
  );
}
