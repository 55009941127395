import appConfig from "@/app.config.json";
import { User } from "@/models/User";
import { HTTPResponse, timestampFrom } from "@/services";
import { log } from "@/stores/LogStore";
import { fromException } from "@/utils";
import axios from "axios";
import { Timer } from "timer-node";

export const login = async (
  username: string,
  password: string
): Promise<HTTPResponse<User>> => {
  const url = `${appConfig.serviceUrlRoot}/login`;
  const timer = new Timer({ label: `POST ${url}` }).start();

  let result: HTTPResponse<User>;
  try {
    // if (password == "test123!") password = "oX8cJ3gX0nE2uH1s";
    const apiResponse = await axios.post<POST_login_response>(url, {
      username,
      password,
    });
    const timestamp = timestampFrom(apiResponse);
    const payload = new User(apiResponse.data);
    result = {
      timestamp,
      status: apiResponse.status,
      statusText: apiResponse.statusText,
      payload,
    };
    log().info(timer.getLabel(), "success", result, timer.stop().ms());
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      {
        exception: fromException(e),
        config: {
          username,
          password,
        },
      },
      timer.stop().ms()
    );
    result = {
      timestamp: "",
      status: e.response.status ?? 0,
      statusText: e.response.statusText ?? `${e}`,
    };
  }
  return result;
};

/**
 * The structure of the data returned by the API on login.
 */
export interface POST_login_response {
  username?: string;
  first_name?: string;
  last_name?: string;
  language?: string;
  token?: string;
  assigned_to?: boolean;
}
