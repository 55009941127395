import { SiteCode } from "./Site";
import { clone } from "@/utils";

/**
 * A container for spare parts and the server time of the most
 * recent response in UTC, formatted as an ISO string.
 */
export class SpareParts {
  serverTimestamp: string = "";
  ids: number[] = [];
  spareParts: { [partId: number]: SparePart } = {};
  /**
   * An indicator set when updating the collection of {@link SparePart}s.
   */
  updating: boolean = false;

  /** Has the store been hydrated from IDB (or initialized) */
  hydrated?: boolean = false;

  /**
   * In the absence of any other data, this is the default state.
   */
  private static readonly DEFAULT: SpareParts = {
    serverTimestamp: "",
    ids: [],
    spareParts: {},
    updating: false,
    hydrated: false,
  };

  /**
   * Create a new instance of the DEFAULTS
   * @returns
   */
  static init() {
    return clone(SpareParts.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for SpareParts.
   */
  static readonly PERSISTENCE_FIELDS: SpareParts = {
    serverTimestamp: "",
    ids: [],
    spareParts: {},
    updating: false,
  };

  /**
   * Returns a string for logging a spare parts update.
   *
   * @param spareParts - The spare part collection that is being uploaded.
   * @returns A string that lists part IDs
   */
  static forLog(spareParts: SpareParts): string {
    return `${spareParts.ids.length} spare part types`;
  }
}

/**
 * Fields returned from API calls for each spare part.
 */
export class SparePart {
  activityid: number = 0;
  transaction_id: number = 0;
  inventory_id: number = 0;
  part_number: string = "";
  site_code: SiteCode = "";
  location: string | null | undefined = undefined;
  part_description: string | null | undefined = undefined;
  part_owner: number | null | undefined = undefined;
  part_manufacturer: string | null | undefined = undefined;
  related_equipment: string | null | undefined = undefined;
  currency: string | null | undefined = undefined;
  measurement_unit: string | null | undefined = undefined;
  onhand_quantity: number | null | undefined = undefined;
  quantity: number | null | undefined = undefined;
}
