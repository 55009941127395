import appConfig from "@/app.config.json";
import { attachmentsTable } from "@/db";
import { Camera, CameraResultType, CameraSource, GalleryImageOptions, GalleryPhotos } from "@capacitor/camera";
import { base64StringToBlob } from "blob-util";
import {
  base64ToImgElement,
  imageProcessor,
  resize,
  sharpen,
} from "ts-image-processor";
import { log } from "@/stores/LogStore";
import { AttachmentViewModel } from "@/models/viewmodels/AttachmentViewModel";

// const photos: Ref<{ [key: string]: string }> = ref({});

export function usePhotoGallery() {
  // const getPhoto = (key?: string): string | undefined => {
  //   if (key) {
  //     return photos.value[key];
  //   } else return undefined;
  // };

  // const getPhotoAsBase64 = async (key?: string): Promise<string> => {
  //   if (key) {
  //     return ("data:image/jpeg;base64," +
  //       (await convertBlobToBase64(photos.value[key]))) as string;
  //   } else return "";
  // };

  const pickImages = async (woid: number,
    activityid: number,
    fileName: string
    ):Promise<any| null>=>{
    const imgs: any[] = [] ;
    let attach: any;
    const arrys: any[] = []
 
    const options :GalleryImageOptions = {
      correctOrientation: true
    }


    const photo = await Camera.pickImages(options);

    const getData =async () => {
      
      for (let i=0;i<photo['photos']['length'];i++)
      {

        const data = await base64FromPath(photo['photos'][i]['webPath'])
        const data2 = await returnAttachment(data,woid,activityid,fileName)

        if(data2!=null)
        {
          imgs.push(data2)
        }
      }
      return imgs
    }

    const res = await  getData()
    return res

  }


  const returnAttachment = async(url: string, woid: number,activityid: number,fileName: string) : Promise<AttachmentViewModel | null> =>{

    if (url) {
      let base64: string = url;
      let parts = base64.split(";base64,");
      let imageType = parts[0].split(":")[1];
      let blob: Blob = base64StringToBlob(parts[1], imageType);
      const initialSize = blob.size;

      const img = await base64ToImgElement(base64);
      let width = img.width;
      let height = img.height;
      console.log(
        `Original image is ${blob.size} bytes (${width} x ${height})`
      );

      const factor = blob.size / 100048576 / 2;
      width /= factor;
      height /= factor;

      console.log(`Factor: ${factor}, first attempt at (${width} x ${height})`);

      while (
        blob.size > 100048576 &&
        width > 100 &&
        height > 100
      ) {
        // reduce image size until it is below the maxUploadSize
        try {
          base64 = await imageProcessor
            .src(base64)
            .pipe(resize({ maxWidth: width, maxHeight: height }), sharpen());
          parts = base64.split(";base64,");
          imageType = parts[0].split(":")[1];

          blob = base64StringToBlob(parts[1], imageType);
          console.log(`Image is now ${blob.size} bytes (${width} x ${height})`);
          // const imageResize = new ImageResize();
          // blob = (await imageResize
          //   .updateOptions({ width: width, outputType: "blob" })
          //   .play(blob)) as Blob;
        } catch (e) {
          log().error(`Error resizing image`, `${e}`);
        }
        width *= 0.9;
        height *= 0.9;
      }
      console.log(`Final image is ${blob.size} bytes (${width} x ${height})`);

      // base64 = await blobToBase64(blob);
      if (blob.size < initialSize)
        log().info(
          `Image resized`,
          `${fileName.replaceAll("_", " ")} resized from ${initialSize} to ${
            blob.size
          } bytes.`,
          base64
        );

      attachmentsTable.setItem(fileName, base64);

      console.log(attachmentsTable)
      // photos.value[fileName] = fileName;
      const attachment = new AttachmentViewModel();
      
      attachment.woid = woid;
      attachment.activityid = activityid;
      attachment.file_name = fileName;
      attachment.file_size = blob.size;
      attachment.file_type = imageType;
      attachment.content = base64;
      return attachment;
    } else {
      return null;
    }
  };



  const removePhoto = (key?: string) => {
    if (key) {
      // delete photos.value[key];
      attachmentsTable.removeItem(key);
    }
  };

  const takePhoto = async (
    woid: number,
    activityid: number,
    fileName: string
  ): Promise<AttachmentViewModel | null> => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 100,
      allowEditing: true,
    });

    if (photo.dataUrl) {
      let base64: string = photo.dataUrl;
      let parts = base64.split(";base64,");
      let imageType = parts[0].split(":")[1];
      let blob: Blob = base64StringToBlob(parts[1], imageType);
      const initialSize = blob.size;

      const img = await base64ToImgElement(base64);
      let width = img.width;
      let height = img.height;
      console.log(
        `Original image is ${blob.size} bytes (${width} x ${height})`
      );

      const factor = blob.size / appConfig.maxUploadSize / 2;
      width /= factor;
      height /= factor;

      console.log(`Factor: ${factor}, first attempt at (${width} x ${height})`);

      while (
        blob.size > appConfig.maxUploadSize &&
        width > 100 &&
        height > 100
      ) {
        // reduce image size until it is below the maxUploadSize
        try {
          base64 = await imageProcessor
            .src(base64)
            .pipe(resize({ maxWidth: width, maxHeight: height }), sharpen());
          parts = base64.split(";base64,");
          imageType = parts[0].split(":")[1];

          blob = base64StringToBlob(parts[1], imageType);
          console.log(`Image is now ${blob.size} bytes (${width} x ${height})`);
          // const imageResize = new ImageResize();
          // blob = (await imageResize
          //   .updateOptions({ width: width, outputType: "blob" })
          //   .play(blob)) as Blob;
        } catch (e) {
          log().error(`Error resizing image`, `${e}`);
        }
        width *= 0.9;
        height *= 0.9;
      }
      console.log(`Final image is ${blob.size} bytes (${width} x ${height})`);

      // base64 = await blobToBase64(blob);
      if (blob.size < initialSize)
        log().info(
          `Image resized`,
          `${fileName.replaceAll("_", " ")} resized from ${initialSize} to ${
            blob.size
          } bytes.`,
          base64
        );

      attachmentsTable.setItem(fileName, base64);
      // photos.value[fileName] = fileName;
      const attachment = new AttachmentViewModel();
      attachment.woid = woid;
      attachment.activityid = activityid;
      attachment.file_name = fileName;
      attachment.file_size = blob.size;
      attachment.file_type = imageType;
      attachment.content = base64;
      return attachment;
    } else {
      return null;
    }
  };

  // const blobToBase64 = (blob: Blob): Promise<string> => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   return new Promise((resolve) => {
  //     reader.onloadend = () => {
  //       resolve(`${reader.result}`);
  //     };
  //   });
  // };

  // const loadPhotos = async (prefix?: string) => {
  //   photos.value = {};
  //   const keys = await attachmentsTable.keys();
  //   const loadedKeys = Object.keys(photos);
  //   for (const key of keys) {
  //     if ((!prefix || key.startsWith(prefix)) && !loadedKeys.includes(key)) {
  //       const photo: string | null = await attachmentsTable.getItem(key);
  //       if (photo) photos.value[key] = photo;
  //     }
  //   }
  // };

  // const savePhotos = async () => {
  //   for (const key of Object.keys(photos)) {
  //     const value = photos.value[key];
  //     attachmentsTable.setItem(key, value);
  //   }
  // };

  return {
    takePhoto,
    // getPhoto,
    removePhoto,
    pickImages
    // loadPhotos,
    // savePhotos,
  };
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };
    reader.readAsDataURL(blob);
  });
}
