import appConfig from "@/app.config.json";
import { logTable } from "@/db";
import { Log, LogEntry, Severity } from "@/models/Log";
import { clone, shortID, timestampOlderThan } from "@/utils";
import { defineStore } from "pinia";

export const log = defineStore("Log", {
  state: (): Log => {
    return Log.init();
  },
  getters: {
    length() {
      return async (): Promise<number> => {
        return await logTable.length();
      };
    },

    async savedEntryCount(): Promise<number> {
      return await logTable.length();
    },
  },
  actions: {
    setLoggingLevel(level: Severity = Severity.INFO) {
      this.loggingLevel = level;
    },

    /**
     * Writes log entries with a timestamped db key, a programmer provided key and optional additional note.
     * @param key Something useful to identify the source of the message; use ClassName.methodName.
     * @param severity The urgency or significance of the log entry.
     * @param note Information about the context or anything that might help support.
     * @param entry An object to be recorded as the log entry; use the data being worked on, or a throwable. [Optional]
     * @param elapsed Elapsed time in milliseconds [Optional]
     */
    async log(
      key: string,
      severity: Severity,
      summary: string,
      detail?: any,
      elapsed?: number
    ) {
      try {
        if (severity <= this.loggingLevel) {
          const logEntry = new LogEntry(
            key,
            severity,
            summary,
            clone(detail),
            elapsed
          );
          if (!this.entries) this.entries = [];
          this.entries.unshift(logEntry);
          if (this.entries.length > appConfig.maxLogViewSize)
            this.entries.length = appConfig.maxLogViewSize; // truncate the array!!!

          // this.entries = [
          //   logEntry,
          //   ...this.entries.slice(0, MAX_LOG_VIEW_SIZE - 1),
          // ];
          // this.entries.unshift(logEntry);
          // if (this.entries.length > MAX_LOG_VIEW_SIZE)
          //   this.entries = this.entries.slice(-MAX_LOG_VIEW_SIZE);
          logTable.setItem(`${logEntry.timestamp}|${shortID()}`, logEntry);
          // console.log(`${now()}`, logEntry); // TODO RB for development
        }
      } catch (e: any) {
        //   console.log(`Error writing to log: ${e.toString()}`);
      }
    },

    /**
     * Create an INFO log entry
     * @param key Something useful to identify the source of the message; use ClassName.methodName.
     * @param note Information about the context or anything that might help support.
     * @param entry An object to be recorded as the log entry; use the data being worked on, or a throwable. [Optional]
     * @param elapsed Elapsed time in milliseconds [Optional]
     */
    async info(key: string, note: string, entry?: any, elapsed?: number) {
      this.log(key, Severity.INFO, note, entry, elapsed);
    },

    /**
     * Create an DEBUG log entry
     * @param key Something useful to identify the source of the message; use ClassName.methodName.
     * @param note Information about the context or anything that might help support.
     * @param entry An object to be recorded as the log entry; use the data being worked on, or a throwable. [Optional]
     * @param elapsed Elapsed time in milliseconds [Optional]
     */
    async debug(key: string, note: string, entry?: any, elapsed?: number) {
      this.log(key, Severity.DEBUG, note, entry, elapsed);
    },

    /**
     * Create an WARN log entry
     * @param key Something useful to identify the source of the message; use ClassName.methodName.
     * @param note Information about the context or anything that might help support.
     * @param entry An object to be recorded as the log entry; use the data being worked on, or a throwable. [Optional]
     * @param elapsed Elapsed time in milliseconds [Optional]
     */
    async warn(key: string, note: string, entry?: any, elapsed?: number) {
      this.log(key, Severity.WARN, note, entry, elapsed);
    },

    /**
     * Create an ERROR log entry
     * @param key Something useful to identify the source of the message; use ClassName.methodName.
     * @param note Information about the context or anything that might help support.
     * @param entry An object to be recorded as the log entry; use the data being worked on, or a throwable. [Optional]
     * @param elapsed Elapsed time in milliseconds [Optional]
     */
    async error(key: string, note: string, entry?: any, elapsed?: number) {
      this.log(key, Severity.ERROR, note, entry, elapsed);
    },

    /**
     * Uses work order data that was persisted in IndexedDB to set this store's
     * state.
     */
    async hydrate(): Promise<void> {
      console.log(`Hydrating LogStore: ${this.hydrated} `);

      if (!this.hydrated) {
        const replacementState = new Log();

        await logTable.iterate((value) => {
          const logEntry = value as LogEntry;
          replacementState.entries.unshift(logEntry);
        });
        if (replacementState.entries.length) {
          if (replacementState.entries.length > appConfig.maxLogViewSize)
            replacementState.entries.length = appConfig.maxLogViewSize; // truncate the array!!!
          this.entries = replacementState.entries;
        }

        this.hydrated = true;
      }
    },

    /**
     * Deletes all log rows persisted in IndexedDB and resets state.
     */
    async purge(): Promise<void> {
      await logTable.clear();
      this.$state = Log.init();
    },

    /**
     * Removes entries that are older than a (temporarily hard-coded) time period.
     *
     * (Probably should not be called with every single log entry.)
     */
    async discardStaleEntries(): Promise<void> {
      // TODO parameterize when log entries can be removed instead
      const THREE_DAYS_MILLIS = 3 * 24 * 60 * 60 * 1000;
      this.entries = this.entries.filter(
        (entry) => !timestampOlderThan(entry.timestamp, THREE_DAYS_MILLIS)
      );
      logTable.keys().then((keys) => {
        keys.forEach((key) => {
          if (timestampOlderThan(key.substring(0, 24), THREE_DAYS_MILLIS)) {
            logTable.removeItem(key);
          }
        });
      });
    },
  },
});
