import appConfig from "@/app.config.json";
import { Site, Sites } from "@/models/Site";
import { requestHeaders, timestampFrom } from "@/services";
import { log } from "@/stores/LogStore";
import { getUserStore } from "@/stores/UserStore";
import { fromException } from "@/utils";
import axios, { AxiosRequestConfig } from "axios";
import { Timer } from "timer-node";

export const fetchSites = async (): Promise<Sites> => {
  const url = `${appConfig.serviceUrlRoot}/sites`;
  const timer = new Timer({ label: `GET ${url}` }).start();

  const config: AxiosRequestConfig = {
    method: "get",
    url,
    params: {
      // no query parameters
    },
    headers: requestHeaders(),
  };

  const result = new Sites();
  const userStore = getUserStore();
  try {
    const response = await axios.get<GET_SitesResponse>(url, config);
    result.serverTimestamp = timestampFrom(response);
    response.data.data.forEach((site) => {
      result.siteCodes.push(site.site_code);
      result.sites[site.site_code] = site;
    });
    if (result.siteCodes.length > 0)
      log().info(
        `sites.getSites`,
        `--> ${result.siteCodes.length} sites for ${userStore.username}`,
        config,
        timer.stop().ms()
      );
  } catch (e: any) {
    log().error(
      timer.getLabel(),
      `exception`,
      { exception: fromException(e), config },
      timer.stop().ms()
    );
  }
  return result;
};

export interface GET_SitesResponse {
  status_code: number;
  server_timestamp: string;
  data: Site[];
}
