import { v4 as UUIDv4 } from "uuid";

/**
 * Creates a deep copy using a JSON intermediate representation.
 *
 * @param object - The object to be cloned; avoid circular references.
 * @returns A copy of the object with the correct type.
 */
export function clone<T>(object: T): T {
  if (object === undefined || object === null) return object;
  try {
    const result = JSON.parse(JSON.stringify(object));
    if (!result) console.log(`Error cloning object!`);
    return result as T;
  } catch (error: unknown) {
    console.log(`Error cloning object: ${error}`);
    return undefined as T;
  }
}

/**
 * Clones a Date object
 *
 * @param object - The object to be cloned; avoid circular references.
 * @returns A copy of the object with the correct type.
 */
export function cloneDate(date: Date): Date {
  return new Date(date.getTime());
}

/**
 * Converts a moment in UTC time to the date in the device's timezone.
 *
 * @param timestamp The UTC timestamp in ISO notation.  'Now' if not provided.
 * @returns The local date.
 */
export function localDate(timestamp: string = now()): string {
  const t = new Date(timestamp);
  const m = t.getMonth() + 1;
  const mm = m < 10 ? `0${m}` : `${m}`;
  const d = t.getDate();
  const dd = d < 10 ? `0${d}` : `${d}`;
  return `${t.getFullYear()}-${mm}-${dd}`;
}

export function localEndOfToday(): string {
  return localDate() + "T23:59:59";
}

export function diff(t1: string, t2: string) {
  try {
    return Math.abs(new Date(t1).getTime() - new Date(t2).getTime());
  } catch (e: unknown) {
    return 0;
  }
}

/**
 * Converts a moment in UTC time to the datetime in the device's timezone.
 *
 * @param timestamp The UTC timestamp in ISO notation.  'Now' if not provided.
 * @returns The local datetime.
 */
export function localDateTime(timestamp: string = now()): string {
  if (!timestamp.endsWith("Z")) timestamp += "Z";
  const t = new Date(timestamp);
  const m = t.getMonth() + 1;
  const mm = m < 10 ? `0${m}` : `${m}`;
  const d = t.getDate();
  const dd = d < 10 ? `0${d}` : `${d}`;
  const h = t.getHours();
  const hh = h < 10 ? `0${h}` : `${h}`;
  const min = t.getMinutes();
  const mmin = min < 10 ? `0${min}` : `${min}`;
  const s = t.getSeconds();
  const ss = s < 10 ? `0${s}` : `${s}`;
  return `${t.getFullYear()}-${mm}-${dd}T${hh}:${mmin}:${ss}`;
}

/**
 * Converts a moment in UTC time to the datetime in the device's timezone, and displays the date in a locale-specific format.
 *
 * @param timestamp The UTC timestamp in ISO notation.  'Now' if not provided.
 * @param locale The locale.
 * @returns The a formatted date.
 */
export function localeDate(
  timestamp: string = now(),
  locale: string = "en"
): string {
  const date = new Date(timestamp);
  return date.toLocaleDateString(locale);
}

/**
 * Converts a moment in UTC time to the datetime in the device's timezone, and displays the time in a locale-specific format.
 *
 * @param timestamp The UTC timestamp in ISO notation.  'Now' if not provided.
 * @param locale The locale.
 * @returns The a formatted date.
 */
export function localeTime(
  timestamp: string = now(),
  locale: string = "en"
): string {
  const date = new Date(timestamp);
  return date.toLocaleTimeString(locale);
}

/**
 * Converts a local timestamp to UTC, removing fractional seconds and 'Z' time zone
 * indicator.
 *
 * @param datetime - The local timestamp to convert
 * @returns The corresponding UTC timestamp without fractional seconds or trailing 'Z'.
 */
export function utcFromLocal(datetime: string): string {
  const t = new Date(datetime);
  return t.toISOString().slice(0, 19);
}

/**
 * Provides this instant (UTC) in ISO notation.
 *
 * @returns The current UTC timestamp.
 */
export function now(): string {
  const date = new Date();
  date.setMilliseconds(0);
  const result = date.toISOString();
  if (result.endsWith(".000") || result.endsWith(".000Z"))
    return result.substring(0, result.indexOf(".000")) + "Z";
  else return result;
}

/**
 * Provides this instant (UTC) in ISO notation without fractional seconds.
 *
 * @returns The current UTC timestamp.
 */
export function nowNoSecondsOrMillis(): string {
  const date = new Date();
  date.setMilliseconds(0);
  date.setSeconds(0);
  return date.toISOString();
}

export function fiveMinuteAlignedFloor(date: string): string {
  let time = new Date(date);
  time.setSeconds(0);
  time.setMilliseconds(0);
  time = new Date(time.getTime() - (time.getMinutes() % 5) * 60_000);
  const timeString = time.toISOString();
  if (timeString.endsWith(".000") || timeString.endsWith(".000Z"))
    return timeString.substring(0, timeString.indexOf(".000")) + "Z";
  else if (!timeString.endsWith("Z")) return timeString + "Z";
  else return timeString;
}

export function fiveMinuteAlignedCeiling(date: string): string {
  let time = new Date(date);
  time.setSeconds(0);
  time.setMilliseconds(0);
  if (time.getMinutes() % 5 > 0)
    time = new Date(time.getTime() + (5 - (time.getMinutes() % 5)) * 60_000);
  const timeString = time.toISOString();
  if (timeString.endsWith(".000") || timeString.endsWith(".000Z"))
    return timeString.substring(0, timeString.indexOf(".000")) + "Z";
  else if (!timeString.endsWith("Z")) return timeString + "Z";
  else return timeString;
}

/**
 * Provides this instant (UTC) in ISO notation.
 *
 * @returns The current UTC timestamp.
 */
export function nowOffset(seconds: number): string {
  const result = new Date(new Date().getTime() + seconds * 1_000).toISOString();
  if (result.endsWith(".000") || result.endsWith(".000Z"))
    return result.substring(0, result.indexOf(".000")) + "Z";
  else return result;
}

/**
 * Provides a date relative to today with time set to midnight.
 *
 * @returns The current date with time set to midnight.
 */
export function date(offset: number | undefined = undefined): Date {
  let theDate = new Date();
  theDate.setHours(0);
  theDate.setMinutes(0);
  theDate.setSeconds(0);
  theDate.setMilliseconds(0);
  if (offset)
    theDate = new Date(theDate.getTime() + offset * 24 * 60 * 60 * 1000);
  return theDate;
}

/**
 * Extracts fields typically found in throwables, and will not return cyclical or other references that
 * cannot be logged in IndexedDB.
 *
 * @param err As thrown from a try block.
 * @returns Useful fields for the log.
 */
export function fromException(err: unknown): unknown {
  if (!err) return err;
  if (typeof err === "string") return err as string;
  const error = err as any;
  const result: any = {};
  if ("name" in error) result.name = error["name"];
  if ("message" in error) result.message = error["message"];
  if ("code" in error) result.code = error["code"];
  if ("status" in error) result.status = error["status"];
  if ("response" in error) {
    const response = error["response"];
    if ("status" in response) result.responseStatus = response["status"];
    if ("statusText" in response)
      result.responseStatusText = response["statusText"];
  }
  return result;
}

/**
 * Provides both paths out of an awaited call.
 *
 * const [err, data] = await andCatch( asyncFunc(args) );
 * if (err) {
 *   log(err);
 * } else {
 *   use(data);
 * }
 *
 * @param promise The result of a call using await.
 * @returns The tuple of [exception, result];
 */
export function andCatch<T>(promise: Promise<T>): Promise<unknown[] | T[]> {
  return promise
    .then((result) => [null, result])
    .catch((reason) => [reason, null]);
}

/**
 * Decides if the provided strings are equal, where any falsey string is deemed equal to another one.
 *
 * @param s1 One string.
 * @param s2 Another string.
 * @returns
 */
export function functionallyEqual(
  s1: string | undefined | null,
  s2: string | undefined | null
): boolean {
  if (!s1 && !s2)
    // both falsey
    return true;
  if ((s1 && !s2) || (s2 && !s1))
    // only one is falsey
    return false;
  return s1 === s2;
}

/**
 * Returns a copy of the argument without a trailing Z
 * @param dateTime - The dateTime which may have a trailing Z
 * @returns A string without a trailing Z
 */
export function removeZulu(dateTime: string | null | undefined): string | null | undefined {
  if (dateTime && dateTime.endsWith('Z'))
    dateTime = dateTime.slice(0, -1);
  return dateTime;
}

/**
 * Determines if an interval has elapsed since a timestamp.
 *
 * @param timestamp The start of the interval being tested.
 * @param intervalMillis The number of milliseconds in the interval.
 * @returns True when intervalMillis milliseconds have elapsed since timestamp.
 */
export function timestampOlderThan(
  timestamp: string,
  intervalMillis: number
): boolean {
  if (!timestamp) return true;
  const then: number = Date.parse(timestamp);
  const now: number = Date.now();
  return then < now - intervalMillis;
}

// for dev time
export function assert(condition: boolean, message: string): void {
  if (!condition) alert(`ASSERTION FAILURE\n${message}`);
}

/**
 *
 * @returns The first 8 digits of a UUID to prevent millisecond key clashes.
 */
export function shortID(): string {
  return UUIDv4().substring(0, 8);
}
