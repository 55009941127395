import { clone } from "@/utils";

export type SiteCode = string;

/**
 * A container for sites and the server time of the most
 * recent response in UTC, formatted as an ISO string.
 */
export class Sites {
  serverTimestamp: string = "";
  siteCodes: SiteCode[] = [];
  sites: { [siteCode: SiteCode]: Site } = {};
  /**
   * An indicator set when updating the collection of {@link Site}s.
   */
  updating: boolean = false;

  /** Has the store been hydrated from IDB (or initialized) */
  hydrated?: boolean = false;

  /**
   * In the absence of any other data, this is the default state.
   */
  private static readonly DEFAULT: Sites = {
    serverTimestamp: "",
    siteCodes: [],
    sites: {},
    updating: false,
    hydrated: false,
  };

  /**
   * Create a new instance of the DEFAULTS
   * @returns
   */
  static init() {
    return clone(Sites.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for Sites.
   */
  static readonly PERSISTENCE_FIELDS: Sites = {
    serverTimestamp: "",
    siteCodes: [],
    sites: {},
    updating: false,
  };

  /**
   * Returns a string for logging a site store update.
   *
   * @param sites - The sites collection from the server.
   * @returns A string that provides the count.
   */
  static forLog(sites: Sites): string {
    return `${sites.siteCodes.length} sites`;
  }
}

/**
 * Fields returned from API calls for each site.
 */
export class Site {
  siteid: number = 0;
  site_code: SiteCode = "";
  site_name: string = "";
  province: string | null | undefined = undefined;
  country: string | null | undefined = undefined;
  latitude: number | null | undefined = undefined;
  longitude: number | null | undefined = undefined;
  data_resolution: number | null | undefined = undefined;
  project_type: string | null | undefined = undefined;
  mwac: string | null | undefined = undefined;
  mwdc: string | null | undefined = undefined;
  tz_name: string | null | undefined = undefined;
  tz_offset: string | null | undefined = undefined;
  related_sites: string | null | undefined = undefined;
}
