import "./registerServiceWorker";
import "./theme/variables.css";
import "@ionic/vue/css/core.css";
import "@ionic/vue/css/display.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/typography.css";
import "vue-material-design-icons/styles.css";

import appConfig from "@/app.config.json";
import App from "@/App.vue";
import router from "@/router";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonicVue } from "@ionic/vue";
import axios from "axios";
import { createPinia } from "pinia";
import { createApp } from "vue";
import VueAxios from "vue-axios";
import { log } from "./stores/LogStore";
import { getUserStore } from "./stores/UserStore";
import { SyncWorker } from "./services/sync-manager";

///*
axios.interceptors.request.use(async (request) => {
  log().debug(
    "API request",
    `${request.method?.toLocaleUpperCase()} ${request.url}`,
    {
      params: request.params,
      data: request.data,
    }
  );
  return request;
});

axios.interceptors.response.use(async (response) => {
  //intercept and update user token for each API response
  try {
    if (
      response.data &&
      typeof response.data === "object" &&
      response.data["jwt_token"]
    ) {
      await getUserStore().replaceToken(response.data["jwt_token"]);
    }
  } catch (e: any) {
    log().error("update user token", "exception", e);
  }

  log().debug(
    `API response: ${response.status} ${response.statusText}`,
    `${response.config.method?.toLocaleUpperCase()} ${response.config.url}`,
    {
      data: response.data,
    }
  );
  return response;
});
//*/

/** Pinia manages all reactive application state */
export const pinia = createPinia();

/** Enable Capacitor PWA Camera, Toast etc. */
defineCustomElements(window);

const app = createApp(App)
  .use(IonicVue)
  .use(pinia)
  .use(router)
  .use(VueAxios, axios);

app.config.globalProperties.window = window;

router.isReady().then(async () => {
  app.mount("#app");
  router.replace({ name: appConfig.WorkOrdersRoute });

  new SyncWorker();
});
