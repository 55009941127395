import { Activity } from "@/models/Activity";
import { SparePart } from "@/models/SparePart";
import { clone, now } from "@/utils";

import { AttachmentViewModel } from "./AttachmentViewModel";

/**
 * The local copy of a work order activity to be displayed and manipulated by the UI.
 */
export class ActivityViewModel {
  activityid: number;
  activity_description: string;
  required_action: string;
  section: string;
  activity_num: number;
  completed: 0 | 1 | null;
  notes: string;
  notes_required: boolean = false;
  input_name: string;
  input_name_required: boolean = false;
  node_name: string;
  node_ref: number;
  attachments: AttachmentViewModel[] = [];
  spare_parts: SparePart[] = [];
  deleted: boolean = false;
  /**
   * What this view model's assignable fields were when it was created.
   */
  readonly snapshot: ActivitySnapshot;

  constructor(a: Activity) {
    this.activityid = a.activityid;
    this.activity_description = a.activity_description;
    this.required_action = a.required_action;
    this.section = a.section;
    this.activity_num = a.activity_num;
    this.completed = a.completed;
    this.notes = a.notes ?? "";
    this.notes_required = a.notes_required;
    this.input_name = a.input_name ?? "";
    this.input_name_required = a.input_name_required;
    this.node_name = a.node_name;
    this.node_ref = a.node_ref;
    this.spare_parts = a.spare_parts;
    this.attachments = a.attachments.map<AttachmentViewModel>(
      (attachment) => new AttachmentViewModel(attachment)
    );
    this.spare_parts = clone(a.spare_parts);
    this.snapshot = {
      timestamp: now(),
      completed: a.completed,
      notes: a.notes,
      input_name: a.input_name,
      spare_parts: clone(a.spare_parts),
      attachments: clone(this.attachments),
    };
  }
}

/**
 * Keeps a memento of changeable state for use when reconciling with server updates.
 */
export type ActivitySnapshot = {
  /**
   * The instant that this snapshot was taken, which may differ from the timestamp
   * of the view model itself. The 'open' VM (and VM clones) have fresher timestamps.
   */
  timestamp: string;
  completed: 0 | 1 | null;
  notes: string | null;
  input_name: string | null;
  spare_parts: SparePart[];
  attachments: AttachmentViewModel[];
};
