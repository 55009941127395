import appConfig from "@/app.config.json";
import { LanguageCode, nameToCode } from "@/locales";
import { POST_login_response } from "@/services/auth-services";
import { WorkOrder, WorkOrderCollation } from "./WorkOrder";
import { Severity } from "./Log";
import { clone } from "@/utils";

/**
 * User attributes and preferences
 */
export class User {
  username: string = "";
  firstName: string = "";
  lastName: string = "";
  token: string = "";
  assignedTo: boolean = true;
  language: LanguageCode = "en";
  collation: WorkOrderCollation = WorkOrder.BY_NUMBER;
  pingInterval: number = appConfig.defaultPingInterval;
  syncInterval: number = appConfig.defaultSyncInterval;
  autoSync: number = appConfig.defaultAutoSync;
  maxAttachmentDownloadSize: number = appConfig.defaultMaxDownloadSize;
  last_username: string = "";
  loggingLevel: Severity = Severity.INFO;
  severityFilter: Severity = Severity.INFO;
  logFilterText: string = "";
  hydrated?: boolean = false;

  constructor(u?: POST_login_response) {
    if (u) {
      this.username = u.username ?? "";
      this.firstName = u.first_name ?? "";
      this.lastName = u.last_name ?? "";
      this.token = u.token ?? "";
      this.assignedTo = u.assigned_to ?? true;
      this.language = nameToCode(u.language);
      this.pingInterval = appConfig.defaultPingInterval; // seconds
      this.syncInterval = appConfig.defaultSyncInterval; // seconds
      this.autoSync = appConfig.defaultAutoSync;
      this.hydrated = true;
    }
  }

  /**
   * In the absence of any other user data, this is User default state.
   */
  private static readonly DEFAULT: User = {
    username: "",
    firstName: "",
    lastName: "",
    token: "",
    assignedTo: true,
    language: "en",
    collation: "BY_NUMBER",
    pingInterval: appConfig.defaultPingInterval,
    syncInterval: appConfig.defaultSyncInterval,
    autoSync: appConfig.defaultAutoSync,
    maxAttachmentDownloadSize: appConfig.defaultMaxDownloadSize,
    last_username: "",
    loggingLevel: Severity.INFO,
    severityFilter: Severity.INFO,
    logFilterText: "",
    reset: false,
    hydrated: false,
  } as User;

  /**
   * Create a new instance of the DEFAULTS
   * @returns
   */
  static init() {
    return clone(User.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for a User.
   */
  static readonly PERSISTENCE_FIELDS: User = {
    username: "",
    firstName: "",
    lastName: "",
    token: "",
    assignedTo: true,
    language: "en",
    collation: "BY_NUMBER",
    pingInterval: appConfig.defaultPingInterval,
    syncInterval: appConfig.defaultSyncInterval,
    autoSync: appConfig.defaultAutoSync,
    maxAttachmentDownloadSize: appConfig.defaultMaxDownloadSize,
    last_username: "",
    loggingLevel: Severity.INFO,
    severityFilter: Severity.INFO,
    logFilterText: "",
    reset: false,
  } as User;

  /**
   * Contains non-null fields that are those to be persisted for a User's Preferences.
   */
  static readonly PER_USER_PERSISTENCE_FIELDS: any = {
    language: "en",
    collation: "BY_NUMBER",
    pingInterval: appConfig.defaultPingInterval,
    syncInterval: appConfig.defaultSyncInterval,
    autoSync: appConfig.defaultAutoSync,
    maxAttachmentDownloadSize: appConfig.defaultMaxDownloadSize,
    loggingLevel: Severity.INFO,
    severityFilter: Severity.INFO,
    logFilterText: "",
  } as User;
}

export enum AutoSyncSetting {
  MANUAL = 3,
  AUTO_WIFI = 2,
  AUTO = 1,
}
