/**
 * Applies the filter to an arbitrary object such that only those fields
 * present in the filter will appear in the result, an object of the same
 * type as the filter instance.
 * 
 * @param object - The object from which data is to be taken (or not).
 * @param filter - An instance of the type with defined values for fields
 * that are to be included in the result.
 * @returns An instance of the desired type with only those fields that are
 * defined in the filter instance.
 */
export function applyFilter<T>(object: any, filter: T): T {
    const result: any = {};
    for (const field in filter) {
        result[field] = object[field];
    }
    return result as T;
}
