import { WorkOrderState } from "@/models/WorkOrder";
import { WorkOrderViewModel } from "@/models/viewmodels/WorkOrderViewModel";
import { functionallyEqual, removeZulu } from "@/utils";

/**
 * The editable fields of a work order (excluding its activities and
 * timesheet entries) which can be uploaded for updates.
 */
export class WorkOrderChanges {
  woid?: number;
  actual_start?: string; // date local
  actual_end?: string; // date local - NOT actual_completion
  current_state?: WorkOrderState;
  pause_reason?: string;
  timesheet?: any[];

  constructor(id: number) {
    this.woid = id;
  }

  /**
   * Gathers any changes made to a work order in the UI.
   *
   * @param updated The work order copy that has been opened in the UI.
   * @returns Any differences from the original.
   */
  static from(updated: WorkOrderViewModel): WorkOrderChanges | null {
    let changesFound = false;
    if (
      !functionallyEqual(updated.snapshot.actual_start, updated.actual_start) ||
      !functionallyEqual(
        updated.snapshot.actual_completion,
        updated.actual_completion
      ) ||
      !functionallyEqual(
        updated.snapshot.current_state,
        updated.current_state
      ) ||
      !functionallyEqual(updated.snapshot.pause_reason, updated.pause_reason)
    ) {
      changesFound = true;
    }

    if (!changesFound) {
      if (updated.snapshot.timesheet?.length !== updated.timesheet?.length) {
        changesFound = true;
      } else {
        for (const ts of updated.snapshot.timesheet) {
          let found = false;
          for (const tsf of updated.timesheet) {
            if (ts.uuid === tsf.uuid && !tsf.deleted) {
              found = true;
              if (
                !functionallyEqual(
                  removeZulu(ts.start_time),
                  removeZulu(tsf.start_time)
                ) ||
                !functionallyEqual(
                  removeZulu(ts.end_time),
                  removeZulu(tsf.end_time)
                )
              ) {
                changesFound = true;
                break;
              }
            }
          }
          if (!found || changesFound) {
            changesFound = true;
            break;
          }
        }
      }
    }

    if (changesFound) {
      const changes: WorkOrderChanges = new WorkOrderChanges(updated.woid);
      changes.actual_start = updated.actual_start;
      changes.actual_end = updated.actual_completion;
      changes.current_state = updated.current_state;
      changes.pause_reason = updated.pause_reason;
      changes.timesheet = updated.timesheet.map((ts) => {
        if (!ts.deleted)
          return {
            start_time: removeZulu(ts.start_time),
            end_time: removeZulu(ts.end_time),
            tz_name: "UTC",
          };
        else return null;
      });
      return changes;
    }
    return null;
  }

  /**
   * Detects if there are any defined changes.
   *
   * @param changes Containing zero or more changes, as produced by {@link WorkOrderChanges.from}.
   * @returns True when at least one change has been detected.
   */
  static isNotEmpty(changes: WorkOrderChanges): boolean {
    return (
      !!changes.actual_start ||
      !!changes.actual_end ||
      !!changes.current_state ||
      !!changes.pause_reason ||
      !!changes.timesheet
    );
  }
}
