import { Activity } from "@/models/Activity";
import { Attachment } from "@/models/Attachment";
import { TimesheetEntry } from "@/models/TimesheetEntry";
import { log } from "@/stores/LogStore";
import { clone } from "@/utils";
import { Timer } from "timer-node";

import { SiteCode } from "./Site";

/**
 * A container for work orders and the server time of the most
 * recent response in UTC, formatted as an ISO string.
 */
export class WorkOrders {
  /**
   * The server's timestamp.  When empty, the contents (if any) of
   * {@link WorkOrders.workOrders} is not to be trusted.
   */
  serverTimestamp: string = "";
  /**
   * The list of {@link WorkOrder} IDs.
   */
  ids: number[] = [];
  /**
   * The collection of {@link WorkOrder}s, keyed by ID.
   */
  workOrders: { [woid: number]: WorkOrder } = {};

  /** Has the store been hydrated from IDB (or initialized) */
  hydrated?: boolean = false;

  /**
   * In the absence of any other work orders data, this is the default state.
   */
  private static readonly DEFAULT: WorkOrders = {
    serverTimestamp: "",
    ids: [],
    workOrders: {},
    hydrated: false,
  };

  static init() {
    const t = new Timer().start();
    try {
      return clone(WorkOrders.DEFAULT);
    } finally {
      log().debug(
        `WorkOrders`,
        `set state from default`,
        undefined,
        t.stop().ms()
      );
    }
  }
}

export class WorkOrderList {
  /**
   * The server's timestamp.  When empty, the contents (if any) of
   * {@link WorkOrders.workOrders} is not to be trusted.
   */
  serverTimestamp: string = "";
  /**
   * The collection of {@link WorkOrder}s.
   */
  workOrders: WorkOrder[] = [];
}

export class WorkOrderFilters {
  /**
   * Filters for when displaying lists of work orders.
   */
  hiddenSites: SiteCode[] = [];
  hiddenTypes: WorkOrderTypeCode[] = [];
  hiddenStates: WorkOrderState[] = [];
  hiddenDateRanges: WorkOrderDateRange[] = [];
  textFilter: string = "";
  assignedTo: string = "";
  toggle: boolean = false;
  grouped: boolean = true;
  openDrawers: string[] = ["overdue", "current", "future", "completed"];
  currentWorkOrderCount: number = 0;
  hydrated?: boolean = false;

  /**
   * In the absence of any other work orders data, this is the default state.
   */
  private static readonly DEFAULT: WorkOrderFilters = {
    hiddenSites: [],
    hiddenTypes: [],
    hiddenStates: [],
    hiddenDateRanges: [],
    textFilter: "",
    assignedTo: "",
    toggle: false,
    grouped: true,
    openDrawers: ["overdue", "current", "future", "completed"],
    currentWorkOrderCount: 0,
    hydrated: false,
  };

  /**
   * Create a new instance of the DEFAULTS
   * @returns
   */
  static init() {
    return clone(WorkOrderFilters.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for WorkOrderFilters.
   */
  static readonly PERSISTENCE_FIELDS: WorkOrderFilters = {
    hiddenSites: [],
    hiddenTypes: [],
    hiddenStates: [],
    hiddenDateRanges: [],
    textFilter: "",
    assignedTo: "",
    toggle:false,
    grouped: true,
    openDrawers: ["current"],
    currentWorkOrderCount: 0,
  };
}

/**
 * Fields returned from the API for individual work orders.
 *
 * Includes static constants for the valid work order states.
 */
export class WorkOrder {
  woid: number;
  nodeid: number;
  wo_number: string = "";
  site_code: SiteCode = "";
  node_name: string = "";
  description: string = "";
  special_instructions?: string | null = "";
  requestor: string = "";
  wo_type: string = "";
  assigned_to: string = "";
  projected_start: string = "";
  projected_completion: string = "";
  actual_start?: string | null = ""; // date local
  actual_completion?: string | null = ""; // date local
  site_node_id: number = 0;
  last_updated_by: string = "";
  current_state: WorkOrderState;
  last_update: string = ""; // timestamp UTC
  tz_name: string = "";
  pause_reason?: string = "";
  activities: Activity[] = [];
  attachments: Attachment[] = [];
  timesheet: TimesheetEntry[] = [];
  workTime: string = "0:00";

  constructor(id: number, nodeid: number, type: string, state: WorkOrderState) {
    this.woid = id;
    this.nodeid = nodeid;
    this.wo_type = type;
    this.current_state = state;
  }

  static readonly COMPLETE = "COMPLETE";
  static readonly DISCARDED = "DISCARDED";
  static readonly DRAFT = "DRAFT";
  static readonly IN_PROGRESS = "IN PROGRESS";
  static readonly PAUSED = "PAUSED";
  static readonly SCHEDULED = "SCHEDULED";

  static readonly CORRECTIVE_MAINTENANCE = "CORRECTIVE MAINTENANCE";
  static readonly PREVENTATIVE_MAINTENANCE = "PREVENTATIVE MAINTENANCE";
  static readonly REMOTE_MAINTENANCE = "REMOTE MAINTENANCE";

  static readonly PAST = "PAST";
  static readonly TODAY = "TODAY";
  static readonly THIS_WEEK = "THIS_WEEK";
  static readonly NEXT_WEEK = "NEXT_WEEK";

  static readonly BY_START = "BY_START";
  static readonly BY_NUMBER = "BY_NUMBER";
  static readonly BY_SITE = "BY_SITE";
  static readonly BY_ASSET = "BY_ASSET";
}

export type WorkOrderTypeCode = string;

export class WorkOrderType {
  type: WorkOrderTypeCode;
  activityInputs: string[] = [];

  constructor(type: WorkOrderTypeCode, activityInputs: string[] = []) {
    this.type = type;
    this.activityInputs = activityInputs;
  }
}

export class WorkOrderTypes {
  types: WorkOrderType[] = [];
  hydrated?: boolean = false;

  /**
   * In the absence of any other work orders data, this is the default state.
   */
  private static readonly DEFAULT: WorkOrderTypes = {
    types: [],
    hydrated: false,
  };

  static init() {
    return clone(WorkOrderTypes.DEFAULT);
  }

  /**
   * Contains non-null fields that are those to be persisted for WorkOrderFilters.
   */
  static readonly PERSISTENCE_FIELDS: WorkOrderTypes = {
    types: [],
  };

  // public length(): number {
  //   return this.types.length;
  // }

  // constructor(types: string[]) {
  //   this.types = [];
  //   for (const type of types) this.types.push(new WorkOrderType(type));
  // }

  // public push(workOrderType: WorkOrderType) {
  //   this.types = this.types.filter((t) => t.type !== workOrderType.type);
  //   this.types.push(workOrderType);
  // }

  // public get(type: string = ""): WorkOrderType | null {
  //   for (const t of this.types) {
  //     if (t.type.toLowerCase() === type.toLowerCase()) return t;
  //   }
  //   return null;
  // }
}

/**
 * The domain of possible values for {@link WorkOrder.current_state} has this type.
 */
export type WorkOrderState =
  | typeof WorkOrder.COMPLETE
  | typeof WorkOrder.DISCARDED
  | typeof WorkOrder.DRAFT
  | typeof WorkOrder.IN_PROGRESS
  | typeof WorkOrder.PAUSED
  | typeof WorkOrder.SCHEDULED;

/**
 * The domain of possible values for WorkOrder dateRangeFilter.
 */
export type WorkOrderDateRange =
  | typeof WorkOrder.PAST
  | typeof WorkOrder.TODAY
  | typeof WorkOrder.THIS_WEEK
  | typeof WorkOrder.NEXT_WEEK;

export type WorkOrderCollation =
  | typeof WorkOrder.BY_START
  | typeof WorkOrder.BY_NUMBER
  | typeof WorkOrder.BY_SITE
  | typeof WorkOrder.BY_ASSET;

/**
 * The domain of possible values for {@link WorkOrder.wo_type} has this type.
 */
// export type WorkOrderType =
//   | typeof WorkOrder.CORRECTIVE_MAINTENANCE
//   | typeof WorkOrder.PREVENTATIVE_MAINTENANCE
//   | typeof WorkOrder.REMOTE_MAINTENANCE;
